import { stringComparer } from "libs/comparers";
import { UnreachableCaseError } from "libs/errors";
import { isEqual } from "libs/predicates";
import { DraftGroupRecipientDoc, getPointer, RecordValue } from "libs/schema";
import { ADMIN_USER_ID } from "libs/shared-constants";
import { combineLatest, concat, debounceTime, distinctUntilChanged, map, Observable, of, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { observeTagIndirectUserMemberIds } from "~/observables/observeTagIndirectUserMemberIds";

export type ObserveUsersWhoWillBePermittedToViewNewThreadResult = [
  RecordValue<"user_profile">[],
  { isLoading: boolean; error?: unknown },
];

export function observeUsersWhoWillBePermittedToViewNewThread(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { draftId: string },
): Observable<ObserveUsersWhoWillBePermittedToViewNewThreadResult> {
  return concat(
    environment.recordLoader.createObserveGetRecordsResult<"user_profile">(),
    observeUsersIdsThatWillBePermittedToViewNewThread(environment, props).pipe(
      debounceTime(50),
      switchMap(({ userIds, meta }) => {
        return environment.recordLoader.observeGetRecords(
          userIds.map((id) => getPointer("user_profile", id)),
          meta,
        );
      }),
    ),
  ).pipe(
    map(([pointerWithRecords, meta]) => {
      const profiles = pointerWithRecords
        .map((p) => p.record)
        .sort((a, b) => stringComparer(a.name, b.name) || stringComparer(a.id, b.id));

      return [profiles, meta];
    }),
  );
}

function observeUsersIdsThatWillBePermittedToViewNewThread(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { draftId: string },
) {
  return environment.recordLoader.observeGetRecord("draft", props.draftId).pipe(
    map(([draft, draftMeta]) => {
      if (draft?.is_reply) {
        throw new Error(
          `[observeUsersWhoWillBePermittedToViewNewThread] Expected a new thread draft, but got a reply draft.`,
        );
      }

      return {
        to: draft?.to || [],
        draftMeta,
      };
    }),
    distinctUntilChanged(isEqual),
    switchMap(({ to, draftMeta }) => {
      const recipients = to.reduce(
        (store, recipient) => {
          switch (recipient.type) {
            case "GROUP": {
              store.groupRecipients.push(recipient);
              break;
            }
            case "USER": {
              store.userIds.push(recipient.user_id);
              break;
            }
            default: {
              throw new UnreachableCaseError(recipient);
            }
          }

          return store;
        },
        { userIds: [] as string[], groupRecipients: [] as DraftGroupRecipientDoc[] },
      );

      if (recipients.groupRecipients.length === 0) {
        return of({ userIds: recipients.userIds, meta: draftMeta });
      }

      return combineLatest(
        recipients.groupRecipients.map((recipient) => {
          return observeTagIndirectUserMemberIds(environment, { tagId: recipient.group_id });
        }),
      ).pipe(
        map((results) => {
          return results.reduce(
            (store, [userIds, meta]) => {
              store.userIds.push(...userIds);
              store.meta.isLoading = store.meta.isLoading || meta.isLoading;
              store.meta.error = store.meta.error ?? meta.error;
              return store;
            },
            { userIds: recipients.userIds.slice(), meta: draftMeta },
          );
        }),
      );
    }),
    map(({ userIds, meta }) => {
      const userIdsSet = new Set(userIds);
      userIdsSet.delete(ADMIN_USER_ID);
      return { userIds: Array.from(userIdsSet), meta };
    }),
  );
}
