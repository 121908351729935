import { map, Observable } from "rxjs";
import { RecordValue } from "libs/schema";
import { cacheReplayForTime } from "libs/rxjs-operators";
import { observeUsersGroupsWithFolderAncestorRecords } from "./observeUsersGroupsWithFolderAncestorRecords";
import { isTagPrivate } from "libs/schema/predicates";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ObserveOptions } from "~/environment/RecordLoader";

export type ObserveMentionableGroupsResult = [MentionableGroup[], { isLoading: boolean }];

export type MentionableGroup = {
  type: "group";
  id: string;
  record: RecordValue<"tag">;
  isPrivate: boolean;
  folderPaths: RecordValue<"tag">[][];
};

export function observeMentionableGroups(
  environment: Pick<ClientEnvironment, "recordLoader" | "subscriptionManager" | "logger">,
  props: {
    currentUserId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveMentionableGroupsResult> {
  const cacheKey = props.currentUserId + options?.fetchStrategy + options?.isLoading;

  const cachedQuery = queryCache.get(cacheKey);

  if (cachedQuery) return cachedQuery;

  const observable = observeUsersGroupsWithFolderAncestorRecords(
    environment,
    { userId: props.currentUserId },
    options,
  ).pipe(
    map(([groups, { isLoading: isUsersGroupsLoading }]): ObserveMentionableGroupsResult => {
      return [
        groups.map(({ group, folderPaths }) => {
          return {
            type: "group",
            id: group.id,
            record: group,
            isPrivate: isTagPrivate(group),
            folderPaths,
          };
        }),
        { isLoading: isUsersGroupsLoading },
      ];
    }),
    cacheReplayForTime({
      timeMs: 100,
      onInit: () => {
        queryCache.set(cacheKey, observable);
      },
      onCleanup: () => {
        queryCache.delete(cacheKey);
      },
    }),
  );

  return observable;
}

const queryCache = new Map<string, Observable<ObserveMentionableGroupsResult>>();
