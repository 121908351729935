import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  observeUsersWhoWillReceiveNotificationForNewThreadDraft,
  ObserveUsersWhoWillReceiveNotificationForNewThreadDraftResult,
} from "~/observables/observeUsersWhoWillReceiveNotificationForNewThreadDraft";

export type UseUsersWhoWillReceiveNotificationForNewThreadDraftResult =
  ObserveUsersWhoWillReceiveNotificationForNewThreadDraftResult;

export function useUsersWhoWillReceiveNotificationForNewThreadDraft(props: { draftId: string | null | undefined }) {
  const environment = useClientEnvironment();

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([draftId, environment]) => {
          if (!draftId)
            return of<UseUsersWhoWillReceiveNotificationForNewThreadDraftResult>([[], { isLoading: false }]);
          return observeUsersWhoWillReceiveNotificationForNewThreadDraft(environment, { draftId });
        }),
      ),
    [props.draftId, environment],
  );

  return useObservableState(observable, DEFAULT_VALUE);
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]),
  Object.freeze({ isLoading: true }),
]) as unknown as UseUsersWhoWillReceiveNotificationForNewThreadDraftResult;
