import { isOrganizationGroupTagRecord } from "libs/schema";
import { map } from "rxjs/operators";
import { getPointer } from "libs/schema";
import { GroupTagRecord } from "libs/schema";
import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { observeExpandedDraftToFieldGroupIds } from "~/observables/observeExpandedDraftToFieldGroupIds";

type UseOrganizationMembersOfDraftResult = [GroupTagRecord[], { isLoading: boolean; error?: unknown }];

export function useOrganizationMembersOfDraft(props: {
  draftId: string | null | undefined;
}): UseOrganizationMembersOfDraftResult {
  const environment = useClientEnvironment();

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([draftId, environment]) => {
          if (!draftId) return of<UseOrganizationMembersOfDraftResult>([[], { isLoading: false }]);

          return observeExpandedDraftToFieldGroupIds(environment, { draftId }).pipe(
            switchMap(({ groupIds, meta }) => {
              return environment.recordLoader.observeGetRecords(
                groupIds.map((groupId) => getPointer("tag", groupId)),
                meta,
              );
            }),
            map(([groupPointersWithRecord, meta]): UseOrganizationMembersOfDraftResult => {
              const organizationGroups = groupPointersWithRecord
                .map((p) => p.record)
                .filter(isOrganizationGroupTagRecord);

              return [organizationGroups, meta];
            }),
          );
        }),
      ),
    [props.draftId, environment],
  );

  return useObservableState(observable, DEFAULT_VALUE);
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]),
  Object.freeze({ isLoading: true }),
]) as unknown as UseOrganizationMembersOfDraftResult;
