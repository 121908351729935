import { isNonNullable } from "libs/predicates";
import { MentionableGroup } from "~/observables/observeMentionableGroups";
import { RecordValue } from "libs/schema";
import { isTagPrivate } from "libs/schema/predicates";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { GetOptions } from "~/environment/RecordLoader";

export type GetMentionableGroupRecordsResult = MentionableGroup[];

export async function getMentionableGroups(
  environment: Pick<ClientEnvironment, "auth" | "recordLoader">,
  options?: GetOptions,
): Promise<GetMentionableGroupRecordsResult> {
  const currentUserId = environment.auth.getAndAssertCurrentUserId();
  const { recordLoader } = environment;

  const [[groups], [folderMembers]] = await Promise.all([
    recordLoader.getGroupsUserHasAccessTo({ currentUserId }, options),
    recordLoader.getTagFolderMembersUserHasAccessTo({ currentUserId }, options),
  ]);

  return groups.map((group): MentionableGroup => {
    return {
      type: "group",
      id: group.id,
      record: group,
      isPrivate: isTagPrivate(group),
      folderPaths: folderPathsForTag(group.id, folderMembers).map((paths) =>
        paths.map((folderId) => groups.find((group) => group.id === folderId)).filter(isNonNullable),
      ),
    };
  });
}

function folderPathsForTag(tagId: string, folders: RecordValue<"tag_folder_member">[]): string[][] {
  return folders
    .filter((folder) => folder.tag_id === tagId)
    .flatMap((folder) => {
      const ancestorPaths = folderPathsForTag(folder.folder_id, folders);

      if (ancestorPaths.length === 0) return [[folder.folder_id]];

      return ancestorPaths.map((paths) => [folder.folder_id, ...paths]);
    });
}
