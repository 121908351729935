import { IEditorMention, IMessageEditorControl } from "~/components/forms/message-editor";
import { IEmailRecipientOption, IRecipientOption } from "~/components/forms/ThreadRecipients";
import { ContainerControls, createFormControl, createFormGroup } from "solid-forms-react";
import { useEffect } from "react";
import { isWindowFocused, WINDOW_FOCUSED$ } from "~/utils/dom-helpers";
import { combineLatest, filter, map, NEVER, Observable, pairwise, skip, Subject, switchMap } from "rxjs";
import { observable, useControlState } from "~/components/forms/utils";
import { differenceBy } from "lodash-comms";
import { isNonNullable } from "libs/predicates";
import { UnreachableCaseError } from "libs/errors";
import { DraftAttachmentDoc, RecordValue, ThreadVisibility } from "libs/schema";
import { MentionableUser, observeMentionableUsers } from "~/observables/observeMentionableUsers";
import { MentionableGroup, observeMentionableGroups } from "~/observables/observeMentionableGroups";
import { deleteDraft } from "~/actions/draft";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export interface IComposeMessageFormValue {
  messageId: string;
  threadId: string;
  type: RecordValue<"draft">["type"];
  isEdit: boolean;
  isReply: boolean;
  branchedFrom: null | {
    threadId: string;
    messageId: string;
  };
  recipients: {
    to: IRecipientOption[];
    cc: IRecipientOption[];
    bcc: IEmailRecipientOption[];
  };
  visibility: ThreadVisibility | null;
  subject: RecordValue<"draft">["new_thread_subject"];
  attachments: DraftAttachmentDoc[];
  body: {
    content: string;
    userMentions: IEditorMention[];
    groupMentions: IEditorMention[];
  };
}

export type IComposeMessageForm = ReturnType<typeof createComposeMessageForm>;

export function createComposeMessageForm(
  initialFormValues: IComposeMessageFormValue,
  options: { recipientsRequired?: boolean } = {},
) {
  const branchedFrom =
    !initialFormValues.branchedFrom ?
      createFormControl(null)
    : createFormGroup({
        threadId: createFormControl(initialFormValues.branchedFrom.threadId),
        messageId: createFormControl(initialFormValues.branchedFrom.messageId),
      });

  return createFormGroup(
    {
      messageId: createFormControl<string>(initialFormValues.messageId),
      threadId: createFormControl<string>(initialFormValues.threadId),
      type: createFormControl(initialFormValues.type),
      isEdit: createFormControl(initialFormValues.isEdit),
      isReply: createFormControl(initialFormValues.isReply),
      branchedFrom,
      recipients: createFormGroup(
        {
          to: createFormControl(initialFormValues.recipients.to, {
            data: {
              focus: new Subject<void>(),
            },
          }),
          cc: createFormControl(initialFormValues.recipients.cc, {
            data: {
              focus: new Subject<void>(),
            },
          }),
          bcc: createFormControl(initialFormValues.recipients.bcc, {
            data: {
              focus: new Subject<void>(),
            },
          }),
        },
        {
          required: !!options.recipientsRequired,
        },
      ),
      visibility: createFormControl(initialFormValues.visibility, {
        required: true,
      }),
      subject: createFormControl(initialFormValues.subject || "", {
        required: true,
        data: {
          focus: new Subject<void>(),
        },
      }),
      attachments: createFormControl(initialFormValues.attachments),
      body: createFormGroup<ContainerControls<IMessageEditorControl["controls"]["body"]>>({
        content: createFormControl(initialFormValues.body.content, {
          required: true,
        }),
        userMentions: createFormControl<IEditorMention[]>(initialFormValues.body.userMentions),
        groupMentions: createFormControl<IEditorMention[]>(initialFormValues.body.groupMentions),
        // Currently in the NewThreadForm component, we only care about
        // changes to the mentionsCount. For this reason, it's fine for us
        // to initialize the control with an incorrect value. In the future
        // we might need to refactor this. See the JSDoc comment on the
        // IMessageEditorControl["controls"]["body"]["possiblyIncorrectMentionsCount"]
        // type for more info.
        possiblyIncorrectMentionsCount: createFormControl(0),
      }),
    },
    {
      validators:
        !options.recipientsRequired ? undefined : (
          (v: IComposeMessageFormValue) => {
            switch (v.type) {
              case "COMMS": {
                return v.recipients.to.length > 0 || v.recipients.cc.length > 0 ? null : { required: "recipients" };
              }
              case "EMAIL": {
                const isNonGroupRecipient = (r: IRecipientOption) => r.type === "user" || r.type === "email";

                return v.recipients.to.some(isNonGroupRecipient) || v.recipients.cc.some(isNonGroupRecipient) ?
                    null
                  : { required: "recipients" };
              }
              default: {
                throw new UnreachableCaseError(v.type);
              }
            }
          }
        ),
    },
  );
}

/* -----------------------------------------------------------------------------------------------*/

export function useAutosaveDraft(
  control: IComposeMessageForm,
  saveDraft: (environment: ClientEnvironment, values: IComposeMessageFormValue) => void,
) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  // Periodically save drafts if the window is focused
  useEffect(() => {
    const sub = WINDOW_FOCUSED$.pipe(
      switchMap((isFocused) => (!isFocused ? NEVER : observable(() => control.rawValue).pipe(skip(1)))),
    ).subscribe((value) => {
      saveDraft(environment, value);
    });

    return () => sub.unsubscribe();
  }, [saveDraft, control, environment]);

  // When we navigate away from this draft,
  // delete the draft if it's empty
  useEffect(() => {
    // If the user has the same draft editor open in two different browsers
    // (e.g. Chrome and Safari), and the draft is blank in both browsers,
    // the other browser will not update with content as the
    // user types. This is intended, as the draft editor ignores updates
    // to the draft doc while open and, since the user is editing in a
    // different browser, there is no way to sync changes via our session
    // store service. Because of this, if the user sends the post then it
    // will cause the draft editor in the other browser to
    // close, causing this useEffect onUnmount callback to run, potentially
    // resulting in the draft being deleted and unsent. SOOooooo, here we
    // check to see if the draft or the editor has any content.
    // If it does, then we don't delete the draft

    return () => {
      if (!isWindowFocused()) return;

      const messageId = control.rawValue.messageId;
      const [draft] = environment.db.getRecord("draft", messageId);

      if (!draft) return;
      if (!isDraftEmpty({ draft, formValue: control.rawValue })) return;

      environment.logger.debug("[useAutosaveDraft] editor close: deleting draft because it's empty...");

      deleteDraft(environment, {
        draftId: control.rawValue.messageId,
        currentUserId,
      });
    };
  }, [control, currentUserId, environment]);
}

/* -----------------------------------------------------------------------------------------------*/

export function isDraftEmpty(props: { draft?: RecordValue<"draft">; formValue: IComposeMessageFormValue }) {
  const { draft, formValue } = props;

  const isContentEmpty = (content?: string) => !content?.trim() || content === "<p></p>";

  return (
    isContentEmpty(draft?.body_html) &&
    isContentEmpty(formValue.body.content) &&
    formValue.attachments.length === 0 &&
    formValue.recipients.to.length === 0 &&
    formValue.recipients.cc.length === 0 &&
    formValue.recipients.bcc.length === 0 &&
    !formValue.subject?.trim()
  );
}

/* -------------------------------------------------------------------------------------------------
 * useSyncDraftChangesFromOtherWindowToControl
 * -----------------------------------------------------------------------------------------------*/

export function useSyncDraftChangesFromOtherWindowToControl(control: IComposeMessageForm) {
  const environment = useClientEnvironment();
  const draftId = useControlState(() => control.rawValue.messageId, [control]);

  useEffect(() => {
    WINDOW_FOCUSED$.pipe(
      switchMap((isFocused) =>
        isFocused ? NEVER : (
          environment.recordLoader.observeGetRecord("draft", draftId, {
            fetchStrategy: "cache",
          })
        ),
      ),
    ).subscribe(([draft]) => {
      if (!draft) return;

      control.patchValue({
        type: draft.type,
        visibility: draft.new_thread_visibility,
        attachments: draft.attachments,
        subject: draft.new_thread_subject ?? undefined,

        // Changes to the `body` and `recipients` are handled by
        // useSyncDraftContentChangesFromOtherWindowToEditor
        // body: ,
        // recipients: ,

        // These properties will not change so we don't need to update them
        // branchedFrom: ,
        // isEdit: ,
        // isReply: ,
        // messageId: ,
        // threadId: ,
      });
    });
  }, [draftId, environment]);
}

/* -----------------------------------------------------------------------------------------------*/

export function usePromptToRemoveRecipientOnMentionRemoval(control: IComposeMessageForm) {
  const { currentUserId } = useAuthGuardContext();
  const environment = useClientEnvironment();

  useEffect(() => {
    const removedMentions$ = observable(() => [
      ...control.rawValue.body.userMentions,
      ...control.rawValue.body.groupMentions,
    ]).pipe(
      pairwise(),
      // Mentions will never be mutated, only added/removed.
      // While mentions are generally added/removed one at a time, they can be
      // changed in bulk if the user deletes a paragraph.
      map(([previous, next]) => {
        const added = differenceBy(next, previous, "id");
        const removed = differenceBy(previous, next, "id");

        return added.length > 0 ?
            { type: "ADDED" as const, value: added }
          : { type: "REMOVED" as const, value: removed };
      }),
      filter((change): change is { type: "REMOVED"; value: IEditorMention[] } => change.type === "REMOVED"),
      map((change) => change.value),
    );

    const removedUsersAndGroups$: Observable<Array<MentionableUser | MentionableGroup>> = combineLatest([
      observeMentionableUsers(environment, { currentUserId }),
      observeMentionableGroups(environment, { currentUserId }),
      removedMentions$,
    ]).pipe(
      map(([[users], [groups], removedMentions]) =>
        removedMentions
          .map((m) => users.find((user) => user.id === m.id) || groups.find((group) => group.id === m.id))
          .filter(isNonNullable),
      ),
    );

    const sub = WINDOW_FOCUSED$.pipe(switchMap((isFocused) => (isFocused ? removedUsersAndGroups$ : NEVER))).subscribe(
      (removedUsersAndGroups) => {
        for (const removed of removedUsersAndGroups) {
          const recipient = control.rawValue.recipients.to.find((r) => r.value === removed.id);

          if (!recipient) continue;
          if (recipient.dontPromptToRemoveOnMentionDeletion) continue;

          const response = confirm(
            `Do you want to remove ${
              removed.type === "group" ? `"${removed.record.name}" group` : `"${removed.profile.name}"`
            } as a recipient?`,
          );

          if (response) {
            control.patchValue({
              recipients: {
                to: control.rawValue.recipients.to.filter((r) => r.value !== removed.id),
              },
            });
          }
        }
      },
    );

    return () => sub.unsubscribe();
  }, [control, currentUserId, environment]);
}

/* -----------------------------------------------------------------------------------------------*/
