import { RecordValue } from "libs/schema";
import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { observeUsersWhoWillBePermittedToViewNewThread } from "~/observables/observeUsersWhoWillBePermittedToViewNewThread";

export type UseUsersWhoWillBePermittedToViewNewThreadResult = [
  RecordValue<"user_profile">[],
  { isLoading: boolean; error?: unknown },
];

export function useUsersWhoWillBePermittedToViewNewThread(props: {
  draftId: string | null | undefined;
}): UseUsersWhoWillBePermittedToViewNewThreadResult {
  const environment = useClientEnvironment();

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([draftId, environment]) => {
          if (!draftId) return of<UseUsersWhoWillBePermittedToViewNewThreadResult>([[], { isLoading: false }]);
          return observeUsersWhoWillBePermittedToViewNewThread(environment, { draftId });
        }),
      ),
    [props.draftId, environment],
  );

  return useObservableState(observable, DEFAULT_VALUE);
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]),
  Object.freeze({ isLoading: true }),
]) as unknown as UseUsersWhoWillBePermittedToViewNewThreadResult;
