import { ComponentType, useRef, Fragment } from "react";
import { List, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { NotFound } from "~/components/NotFound";
import { ESCAPE_TO_BACK_COMMAND } from "~/utils/common-commands";
import { Tooltip } from "~/components/Tooltip";
import { BsLockFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import * as MainLayout from "~/page-layouts/main-layout";
import { OutlineButton } from "~/components/OutlineButtons";
import { useRegisterCommands } from "~/environment/command.service";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { useTag } from "~/hooks/useTag";
import { useTagSubscribers } from "~/hooks/useTagSubscriberUsers";
import { isTagPrivate } from "libs/schema/predicates";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { showNotImplementedToastMsg } from "~/environment/toast-service";
import { PointerWithRecord, TagSubscriptionPreference } from "libs/schema";
import { useUserProfile } from "~/hooks/useUserProfile";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { entryCSSClasses } from "~/components/content-list/layout";
import { useUserContactInfo } from "~/hooks/useUserContactInfo";
import { useIsCurrentRouteForAGroup } from "~/hooks/useIsCurrentRouteForAGroup";
import { TagInviteDialogState } from "~/dialogs/tag-invite/TagInviteDialog";
import { renderGroupName } from "~/utils/groups-utils";

export const TagSubscribersView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  const params = useParams();

  const isGroupView = useIsCurrentRouteForAGroup();

  const [tag, { isLoading: isTagLoading }] = useTag(params.tagId);

  const [subscribers] = useTagSubscribers({
    tagId: params.tagId,
  });

  useRegisterCommands({
    commands() {
      const commands = [ESCAPE_TO_BACK_COMMAND];

      if (tag) {
        commands.push({
          label: "Add subscribers...",
          altLabels: ["Update subscribers...", "Add group members...", "Invite to group...", "Invite subscribers..."],
          callback: () => {
            TagInviteDialogState.open({
              tagId: tag.id,
            });
          },
        });
      }

      return commands;
    },
    deps: [tag],
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [tag],
  });

  if (!tag) {
    if (isTagLoading) {
      return <div>Loading...</div>;
    }

    const title = isGroupView ? "Group Not Found" : "Tag Not Found";

    return <NotFound title={title} />;
  }

  const isPrivate = isTagPrivate(tag);

  return (
    <>
      <Helmet>
        <title>{renderGroupName(tag)} subscribers | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef} theme={isPrivate ? "dark" : "light"} className="flex-col">
        <h1 className="text-3xl">
          <span># {tag.name} subscribers</span>
          {isPrivate && (
            <Tooltip side="bottom" content="This channel is private and only visible to invited members">
              <span className="text-2xl inline-flex mx-2 hover:cursor-help mt-1 text-slate-8">
                <small>
                  <BsLockFill />
                </small>
              </span>
            </Tooltip>
          )}
        </h1>

        <MainLayout.HeaderMenu>
          <li>
            <OutlineButton
              theme={isPrivate ? "dark" : "light"}
              onClick={(e) => {
                e.preventDefault();

                TagInviteDialogState.open({
                  tagId: tag.id,
                });
              }}
            >
              <FaPlus size={16} className="mr-1 text-slate-11" /> <small>Update subscribers</small>
            </OutlineButton>
          </li>
        </MainLayout.HeaderMenu>
      </MainLayout.Header>

      <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
        {subscribers.length === 0 ?
          <EmptyListMessage text="None." />
        : <ContentList
            className="mb-20"
            onEntryAction={() => {
              showNotImplementedToastMsg(`
                Unfortunately, you can't currently view user profiles. 
                Annoying, I know. I want this feature too...
              `);
            }}
            autoFocus
          >
            {(() => {
              let previousSubscriptionPreference: string | undefined;

              return subscribers.map(({ userProfile, subscriptionPreference }, index) => {
                const isNewSubscriptionPreference = subscriptionPreference !== previousSubscriptionPreference;

                previousSubscriptionPreference = subscriptionPreference;

                return (
                  <Fragment key={userProfile.id}>
                    {isNewSubscriptionPreference && <SectionHeader preference={subscriptionPreference} />}

                    <SubscriberEntry tagId={tag.id} userId={userProfile.id} relativeOrder={index} />
                  </Fragment>
                );
              });
            })()}
          </ContentList>
        }
      </ListScrollbox>
    </>
  );
};

const SectionHeader: ComponentType<{
  preference: TagSubscriptionPreference;
}> = (props) => {
  const label =
    props.preference === "all" ? "Subscribed to all"
    : props.preference === "all-new" ? "Subscribed to new threads"
    : props.preference === "involved" ? "Mentions only"
    : "Unknown subscription preference";

  return (
    <div className="mt-8 mb-2 mx-12 border-l-2 border-white text-black font-medium flex items-center">
      <h2 className="text-2xl">{label}</h2>
    </div>
  );
};

const SubscriberEntry: ComponentType<{
  tagId: string;
  userId: string;
  relativeOrder: number;
}> = (props) => {
  const [userProfile] = useUserProfile(props.userId);
  const [userContactInfo] = useUserContactInfo(props.userId);
  const entryData = useAsPointerWithRecord("user_profile", userProfile);

  if (!userProfile || !entryData) return null;

  return (
    <List.Entry<PointerWithRecord<"user_profile">>
      id={userProfile.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
    >
      <div className={entryCSSClasses}>
        <div className="w-full flex items-center">
          <span className="flex-1">{userProfile.name}</span>

          <span className="flex-[4] text-slate-9">{userContactInfo?.email_address}</span>
        </div>
      </div>
    </List.Entry>
  );
};
