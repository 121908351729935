import { RecordValue } from "libs/schema";
import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { observeUsersPermittedToViewThread } from "~/observables/observeUsersPermittedToViewThread";

export type UseUsersPermittedToViewThreadResult = [
  RecordValue<"user_profile">[],
  { isLoading: boolean; error?: unknown },
];

export function useUsersPermittedToViewThread(props: {
  threadId: string | null | undefined;
}): UseUsersPermittedToViewThreadResult {
  const environment = useClientEnvironment();

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([threadId, environment]) => {
          if (!threadId) return of<UseUsersPermittedToViewThreadResult>([[], { isLoading: false }]);
          return observeUsersPermittedToViewThread(environment, { threadId });
        }),
      ),
    [props.threadId, environment],
  );

  return useObservableState(observable, DEFAULT_VALUE);
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]),
  Object.freeze({ isLoading: true }),
]) as unknown as UseUsersPermittedToViewThreadResult;
