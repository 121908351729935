import { Helmet } from "react-helmet-async";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { toast } from "~/environment/toast-service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  deleteDraftCommand,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
  threadSubscriptionCommands,
} from "~/utils/common-commands";
import { ComponentType, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { IListOnEntryActionEvent, IListRef, ListScrollbox } from "~/components/list";
import { RedirectToDefaultInboxSection, useInboxZeroConfetti } from "./utils";
import { Tooltip } from "~/components/Tooltip";
import { Link, useParams } from "react-router-dom";
import { cx, css } from "@emotion/css";
import { NextScheduledDeliveryHeader } from "./NextScheduledDeliveryHeader";
import * as MainLayout from "~/page-layouts/main-layout";
import { NotificationCountBadge } from "~/components/NotificationCountBadge";
import { buildThreadViewPrevNextStateForInboxView } from "./thread-prev-next-service-utils";
import { withNewCommandContext } from "~/environment/command.service";
import { BlockingInboxProgressBar } from "./BlockingInboxProgressBar";
import Confetti from "react-confetti";
import { EmptyInboxMessage } from "./EmptyInboxMessage";
import { INavigateServiceOptions, updateNavigationHistoryState } from "~/environment/navigate.service";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { useInboxSection } from "~/hooks/useInboxSection";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { PointerWithRecord, RecordValue } from "libs/schema";
import { useInboxSectionIds } from "~/hooks/useInboxSectionIds";
import { triageThread } from "~/actions/notification";
import { deleteDraft } from "~/actions/draft";
import { onNotificationSelectNavigateToThread } from "~/components/content-list/NotificationEntry";
import { useInboxEntries } from "~/hooks/useInboxEntries";
import { UnreachableCaseError } from "libs/errors";
import { useInboxSubsection } from "~/hooks/useInboxSubsection";
import { onDraftsEntrySelect } from "~/components/content-list/DraftEntry";
import { useIsDefaultInboxSection } from "~/hooks/useIsDefaultInboxSection";
import { isUuid } from "libs/uuid";
import { EndOfListMsg } from "~/components/EndOfListMsg";
import { useListPaging } from "~/hooks/useListPaging";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { InboxDraftEntry, InboxNotificationEntry } from "./InboxEntry";
import { useInboxSubsectionIds } from "~/hooks/useInboxSubsectionIds";
import { useLocationState } from "~/hooks/useLocationState";
import { useAttemptRefetchOnError } from "~/hooks/useAttemptRefetchOnError";
import { useInboxGroups } from "~/hooks/useInboxGroups";
import { renderGroupName } from "~/utils/groups-utils";
import { inboxState } from "./state";
import { withOfflineFirstIfSynced } from "~/components/withOfflineFirstIfSynced";
import * as Toggle from "@radix-ui/react-toggle";
import { MarkAllDoneEntryAction, SetReminderForAllEntryAction } from "~/components/content-list/layout";
import { useControl, createFormControl, IFormControl } from "solid-forms-react";
import { useControlState } from "~/components/forms/utils";
import { useAddDropShadowWhenSticky } from "~/hooks/useAddDropShadowWhenSticky";
import { useRegisterBulkRecordActionCommands } from "~/hooks/useRegisterBulkEntryActions";
import { useDoesThreadHaveDraft } from "~/hooks/useDoesThreadHaveDraft";
import { observeZustandState } from "~/utils/rxjs-utils";
import { switchMap, timer } from "rxjs";
import { useIsDesktopBrowser } from "~/hooks/useIsDesktopBrowser";
import useConstant from "use-constant";
import { useInitialQueryLimit } from "~/hooks/useInitialQueryLimit";

/* -------------------------------------------------------------------------------------------------
 * InboxView
 * -----------------------------------------------------------------------------------------------*/

type TInboxEntry = PointerWithRecord<"draft"> | PointerWithRecord<"notification">;

export const InboxView: ComponentType<{}> = withOfflineFirstIfSynced(
  withNewCommandContext(() => {
    const { settings } = useCurrentUserSettings();
    const inboxLayout = settings?.inbox_layout;
    const params = useParams<{ inboxSectionId: string }>();
    const inboxSectionId = params.inboxSectionId && isUuid(params.inboxSectionId) ? params.inboxSectionId : null;

    const headerRef = useRef<HTMLElement>(null);
    const listRef = useRef<IListRef<TInboxEntry>>(null);

    const hasSelectedGroups = inboxState((s) => s.selectedGroups.length > 0);

    const showGroupFiltersControl = useControl(() => createFormControl(hasSelectedGroups));

    const [inboxSection, { isLoading: isInboxSectionLoading }] = useInboxSection(inboxSectionId);

    const isListRefSet = !!inboxSection && !inboxSection.data.is_reindexing;

    if (!inboxSectionId) {
      // Note regarding old Comms to new Comms migration:
      // We are unable to redirect users using an old Comms url for a specific inbox
      // section to a new Comms url. This is because we chose not to derive the new Comms
      // inbox section IDs from the old inbox section IDs during the migration. Instead we
      // derive the inbox section IDs from the inbox section's "tagId" property.
      // While this might affect specific user's bookmarks, it should be trivial for a user
      // to update their bookmarks to the correct URL. I don't expect there will be any direct
      // links to specific user's inboxes that have been otherwise saved.

      return <RedirectToDefaultInboxSection />;
    }

    if (!inboxSection) {
      if (isInboxSectionLoading) {
        return <EmptyListMessage text="Loading..." loading={true} />;
      }

      return <RedirectToDefaultInboxSection />;
    }

    return (
      <>
        <Helmet>
          <title>Inbox | Comms</title>
        </Helmet>

        <MainLayout.Header ref={headerRef} noSticky className={cx("sm-max-w:pr-0 flex-col")}>
          <div className="flex items-center">
            <InboxHeader currentInboxSectionId={inboxSectionId} />

            <div className="flex-1" />

            {settings?.enable_focus_mode && (
              <Tooltip side="bottom" content="Focus Mode is on">
                <span className="text-slate-9 mr-4 cursor-help">Focus Mode</span>
              </Tooltip>
            )}

            {settings?.enable_scheduled_delivery && <NextScheduledDeliveryHeader />}
          </div>

          {inboxLayout === "blocking-inbox" && (
            <div className="mt-2">
              <BlockingInboxProgressBar inboxSectionId={inboxSectionId} />
            </div>
          )}
        </MainLayout.Header>

        <InboxActionsBar
          listRef={listRef}
          isListRefSet={isListRefSet}
          inboxSectionId={inboxSectionId}
          showGroupFiltersControl={showGroupFiltersControl}
        />

        <InboxGroupFilters inboxSectionId={inboxSectionId} showGroupFiltersControl={showGroupFiltersControl} />

        {inboxSection.data.is_reindexing ?
          <EmptyListMessage text="Updating this inbox section...">
            <div className="max-w-[600px] mt-4">
              <p className="text-slate-9 font-medium">
                After creating or updating an inbox section, we need to reprocess your notifications to see which of
                them belong here. Please check back later. This may take a few minutes.
              </p>
            </div>
          </EmptyListMessage>
        : <InboxEntries
            inboxLayout={inboxLayout}
            inboxSectionId={inboxSectionId}
            headerRef={headerRef}
            listRef={listRef}
          />
        }
      </>
    );
  }),
);

const InboxActionsBar: ComponentType<{
  listRef: React.RefObject<IListRef<TInboxEntry>>;
  isListRefSet: boolean;
  inboxSectionId: string;
  showGroupFiltersControl: IFormControl<boolean>;
}> = (props) => {
  const { listRef, isListRefSet, showGroupFiltersControl } = props;

  const elementRef = useRef<HTMLDivElement>(null);

  const [_, { isLoading: areInboxGroupsLoading }] = useInboxGroups(props.inboxSectionId);

  const showFilters = useControlState(() => showGroupFiltersControl.value, [showGroupFiltersControl]);

  useAddDropShadowWhenSticky(elementRef);

  return (
    <MainLayout.ActionsBar
      listRef={listRef}
      isListRefSet={isListRefSet}
      multiSelectActions={
        <>
          <MarkAllDoneEntryAction />
          <SetReminderForAllEntryAction />
        </>
      }
    >
      {areInboxGroupsLoading && (
        <div className="flex items-center text-sm text-slate-11 mr-4">
          <img src={"/comms-icon.gif"} alt="Loading" className="w-[30px] pr-2" /> Inbox loading
        </div>
      )}

      <Toggle.Root
        className={cx(
          "inline-flex items-center border group px-2 py-1",
          "rounded",
          "border",
          "border-slate-9",
          showFilters ? "bg-slate-12 text-white" : "hover:bg-slateA-3",
        )}
        onPressedChange={(isPressed) => showGroupFiltersControl.setValue(isPressed)}
      >
        <small>Filters</small>
      </Toggle.Root>
    </MainLayout.ActionsBar>
  );
};

/**
 * Shows the group filters for the inbox (if there's at least one group).
 */
const InboxGroupFilters: ComponentType<{
  inboxSectionId: string | null | undefined;
  showGroupFiltersControl: IFormControl<boolean>;
}> = (props) => {
  const { showGroupFiltersControl } = props;
  const environment = useClientEnvironment();
  const { inboxEntries, selectedGroups, toggleGroup, clearSelectedGroups } = inboxState((s) => s);
  const [inboxGroups, { isLoading }] = useInboxGroups(props.inboxSectionId);

  const showFilters = useControlState(() => showGroupFiltersControl.value, [showGroupFiltersControl]);

  useEffect(() => {
    if (isLoading) return;

    const removedGroups = selectedGroups.filter((groupId) => !inboxGroups.some((group) => group.id === groupId));
    removedGroups.forEach((groupId) => toggleGroup(environment, groupId));
  }, [environment, inboxEntries, inboxGroups, isLoading, selectedGroups, toggleGroup]);

  // If we toggle off the filters then we should clear the selected groups.
  useEffect(() => {
    if (showFilters) return;
    clearSelectedGroups();
  }, [showFilters, inboxGroups, clearSelectedGroups]);

  useRegisterCommands({
    commands: () => {
      if (isLoading || !inboxGroups.length) return [];

      return [
        {
          label: "Clear all filters",
          callback() {
            clearSelectedGroups();
          },
        },
        ...inboxGroups.map((group) => {
          const selected = selectedGroups.includes(group.id);

          return {
            label: `${selected ? "Remove filter" : "Filter by"} ${renderGroupName(group)}`,
            callback() {
              toggleGroup(environment, group.id);
            },
          };
        }),
      ];
    },
    deps: [isLoading, inboxGroups, selectedGroups],
  });

  if (!showFilters) return null;

  return (
    <div className="flex flex-wrap overflow-y-auto max-h-[120px] mx-4 sm-w:mx-8 md-w:mx-12 pb-2 space-x-2 space-y-2 border-y border-slate-9">
      {/* 
        Tailwind's "space-x" style has a strange affect on the first element in a list. We add an unstyled, invisble div to catch this effect
        and nullify it.
      */}
      <div />

      {inboxGroups.length === 0 && <span>No groups to filter on</span>}

      {inboxGroups.map((group, index) => (
        <span
          key={index}
          className={cx(
            "text-xs border-slateA-9 text-slateA-9 px-[10px] py-[3px] border rounded cursor-pointer truncate max-w-[10rem]",
            "hover:border-black hover:text-black shrink-0",
            selectedGroups.includes(group.id) && "bg-slate-12 !text-white",
          )}
          onClick={() => toggleGroup(environment, group.id)}
        >
          {renderGroupName(group)}
        </span>
      ))}
    </div>
  );
};

const InboxEntries: ComponentType<{
  inboxLayout: RecordValue<"user_settings">["settings"]["inbox_layout"];
  inboxSectionId: string;
  headerRef: React.RefObject<HTMLElement>;
  listRef: React.RefObject<IListRef<TInboxEntry>>;
}> = withNewCommandContext((props) => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const defaultInitialLimit = useInitialQueryLimit(100);
  const initialLimit = useInboxEntriesLocationState()?.inboxEntriesLimit || defaultInitialLimit;

  const [
    inboxEntries,
    {
      isLoading: areInboxEntriesLoading,
      nextId,
      limit: inboxEntriesLimit,
      fetchMore,
      refetch: refetchInboxEntries,
      error: inboxEntriesError,
    },
  ] = useInboxEntries({
    inboxSectionId: props.inboxSectionId,
    initialLimit,
  });

  const filteredInboxEntries = useFilteredInboxEntries(inboxEntries);

  const noMoreThreads = !nextId && !areInboxEntriesLoading && !inboxEntriesError;

  useListPaging({
    fetchMore,
    isLoading: areInboxEntriesLoading,
    isListEnd: noMoreThreads,
    pagingScrollboxRef: scrollboxRef,
  });

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<TInboxEntry>();

  useRegisterBulkRecordActionCommands({
    priority: { delta: 1 },
    listRef: props.listRef,
  });

  const inboxSubsectionCount = useInboxSubsectionCount(props.inboxSectionId);

  const hasInboxNotification = useMemo(() => {
    return inboxEntries.some((p) => p.type === "notification");
  }, [inboxEntries]);

  return (
    <>
      <RegisterInboxViewCommands inboxSectionId={props.inboxSectionId} useFocusedEntry={useFocusedEntry} />

      <ListScrollbox isBodyElement offsetHeaderEl={props.headerRef} onlyOffsetHeaderElIfSticky>
        <ContentList<TInboxEntry>
          ref={props.listRef}
          onEntryFocused={setFocusedEntry}
          onEntryAction={(event) =>
            onInboxEntrySelect(
              {
                event,
                inboxEntriesLimit: inboxEntriesLimit || initialLimit,
              },
              {
                state: buildThreadViewPrevNextStateForInboxView(props.inboxSectionId),
              },
            )
          }
          className="mb-20"
          autoFocus
        >
          {(() => {
            let previousInboxSubsectionId: string | undefined;

            return filteredInboxEntries.map((entry, index) => {
              switch (entry.type) {
                case "notification": {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const inboxSubsectionId = entry.inbox_subsection_id!;

                  const isNewInboxSection = previousInboxSubsectionId !== inboxSubsectionId;

                  previousInboxSubsectionId = inboxSubsectionId;

                  return (
                    <Fragment key={entry.id}>
                      {inboxSubsectionCount > 1 && isNewInboxSection && (
                        <SubsectionHeader sectionId={props.inboxSectionId} subsectionId={inboxSubsectionId} />
                      )}

                      <InboxNotificationEntry key={entry.id} notificationId={entry.id} relativeOrder={index} />
                    </Fragment>
                  );
                }
                case "draft": {
                  return <InboxDraftEntry key={entry.id} draftId={entry.id} relativeOrder={index} />;
                }
                default: {
                  throw new UnreachableCaseError(entry.type);
                }
              }
            });
          })()}

          {hasInboxNotification && <EndOfListMsg isEnd={noMoreThreads} />}
        </ContentList>
      </ListScrollbox>

      {inboxEntriesError ?
        <ErrorLoadingInbox refetch={refetchInboxEntries} isLoading={areInboxEntriesLoading} error={inboxEntriesError} />
      : <EmptyInbox isLoading={areInboxEntriesLoading} hasInboxNotification={hasInboxNotification} />}
    </>
  );
});

/* -----------------------------------------------------------------------------------------------*/

const EmptyInbox: ComponentType<{
  isLoading: boolean;
  hasInboxNotification: boolean;
}> = (props) => {
  const { settings } = useCurrentUserSettings();

  const { showConfetti, windowWidth, windowHeight } = useInboxZeroConfetti(props.isLoading, props.hasInboxNotification);

  if (props.isLoading || props.hasInboxNotification) {
    return null;
  }

  return (
    <>
      {showConfetti && !settings?.enable_focus_mode && (
        <div className="pointer-events-none w-screen h-screen absolute top-0 left-0 z-[2000]">
          <Confetti width={windowWidth} height={windowHeight} recycle={false} tweenDuration={5000} gravity={0.1} />
        </div>
      )}

      <EmptyInboxMessage />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ErrorLoadingInbox: ComponentType<{
  refetch: () => void;
  isLoading: boolean;
  error?: unknown;
}> = (props) => {
  const { refetchFailed } = useAttemptRefetchOnError(props);

  if (!props.error || !refetchFailed) {
    return null;
  }

  return (
    <EmptyListMessage text="Oops, something went wrong.">
      <div className="max-w-[600px] mt-4">
        <p className="text-slate-9 font-medium">We couldn't load your inbox entries.</p>
      </div>

      <div className="h-20" />
    </EmptyListMessage>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useFilteredInboxEntries(unfilteredInboxEntries: RecordValue<"inbox_entry">[]) {
  const environment = useClientEnvironment();
  const [filteredInboxEntries, setFilteredInboxEntries] = useState<RecordValue<"inbox_entry">[]>([]);

  useEffect(() => {
    inboxState.getState().setInboxEntries(environment, unfilteredInboxEntries);
  }, [unfilteredInboxEntries, environment]);

  // Note that we could simply access the zustand store directly in react as a hook here.
  // I.e. const { filteredInboxEntries, setInboxEntries } = inboxState();
  //
  // Instead we're accessing the state inside a useEffect hook and subscribing to it because, on
  // the initial render of this component, we want filteredInboxEntries to be length 0 for perf
  // reasons. Currently rendering the inbox entries is slow. If we render them synchronously when
  // the inbox is initially navigated to, then navigating to the inbox appears to be slow. By
  // rendering the inbox entries after the initial render, we can render the inbox page faster
  // (though rendering the actual inbox entries will still be slow).
  //
  // So instead of accessing the zustand state directly in the component, we're accessing it via
  // this useEffect hook.
  useEffect(() => {
    const sub = timer(10)
      .pipe(switchMap(() => observeZustandState(inboxState, (state) => state.filteredInboxEntries)))
      .subscribe(setFilteredInboxEntries);

    return () => sub.unsubscribe();
  }, []);

  return filteredInboxEntries;
}

/* -----------------------------------------------------------------------------------------------*/

const SubsectionHeader: ComponentType<{
  sectionId: string;
  subsectionId: string;
}> = (props) => {
  const elRef = useRef<HTMLDivElement>(null);
  const { isDefaultInboxSection } = useIsDefaultInboxSection(props.sectionId);
  const [subsection] = useInboxSubsection(props.subsectionId);

  useAddDropShadowWhenSticky(elRef, [!!subsection]);

  if (!subsection) return null;

  // const subsectionInboxEntriesCount = subsection.inbox_entries_count;
  const subsectionInboxEntriesCount = 0;

  return (
    <div
      ref={elRef}
      className={`
        px-4 sm-w:px-8 md-w:px-12 sticky top-0 bg-white z-20
        mt-8 first:mt-4 mb-2 border-l-2 border-white text-black 
        font-medium flex items-center py-2
      `}
    >
      <h2 className="inline-flex shrink-0 items-center text-2xl">
        {subsectionInboxEntriesCount > 0 && (
          <NotificationCountBadge
            count={subsectionInboxEntriesCount}
            className="bg-slate-4 border-blackA-7 text-black"
          />
        )}

        {subsection.name}
      </h2>

      {subsection.description && (
        <>
          <span className="mx-2 text-slate-9">-</span>

          <p className="italic text-slate-9 font-normal">{subsection.description}</p>
        </>
      )}

      {!isDefaultInboxSection && (
        <>
          <div className="flex-1" />

          <Link to={`/inbox/${props.sectionId}/edit`} className="underline">
            Edit
          </Link>
        </>
      )}
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const InboxHeader: ComponentType<{
  currentInboxSectionId: string;
}> = (props) => {
  const [inboxSectionIds] = useInboxSectionIds();

  return (
    <div className="flex mr-4 overflow-x-auto overflow-y-hidden">
      {inboxSectionIds.map((sectionId, index) => {
        return (
          <HeaderInboxSectionEntry
            key={sectionId}
            inboxSectionId={sectionId}
            isCurrentInboxSection={props.currentInboxSectionId === sectionId}
            isFirstEntry={index === 0}
          />
        );
      })}

      {/* <Tooltip side="bottom" content="Edit inbox sections">
        <span
          className={cx(
            "inbox-section-button touch:hidden",
            "mx-4 text-3xl flex items-center cursor-pointer",
            addInboxSectionButtonCss,
          )}
          onClick={() => {
            EditInboxSectionsDialogState.open();
          }}
        >
          edit
        </span>
      </Tooltip> */}
    </div>
  );
};

const addInboxSectionButtonCss = css`
  opacity: 0%;

  @media (hover: hover) and (pointer: fine) {
    header:hover & {
      opacity: 20%;
    }

    &.inbox-section-button:hover {
      opacity: 100%;
    }
  }
`;

const HeaderInboxSectionEntry: ComponentType<{
  inboxSectionId: string;
  isCurrentInboxSection: boolean;
  isFirstEntry: boolean;
}> = (props) => {
  const [section] = useInboxSection(props.inboxSectionId);

  if (!section) return null;

  return (
    <>
      {!props.isFirstEntry && <span className="text-3xl mx-4 text-slate-7">&bull;</span>}

      <h1 className={cx("text-3xl", !props.isCurrentInboxSection && "opacity-40")}>
        <Link to={`/inbox/${props.inboxSectionId}`}>{section.name}</Link>
      </h1>
    </>
  );
};

/* -------------------------------------------------------------------------------------------------
 * useRegisterInboxViewCommands
 * -----------------------------------------------------------------------------------------------*/

const RegisterInboxViewCommands: ComponentType<{
  inboxSectionId: string;
  useFocusedEntry: () => TInboxEntry | null;
}> = ({ inboxSectionId, useFocusedEntry }) => {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const focusedEntry = useFocusedEntry();

  const [doesFocusedNotificationHaveADraft] = useDoesThreadHaveDraft({
    threadId: focusedEntry?.table === "notification" ? focusedEntry.record.thread_id : null,
    // In the environment we create and maintain a subscription to all of the user's drafts.
    // For this reason, we can use a cache-only fetch strategy here. Note that the environment
    // creates a subscription to the user's drafts after a delay, so if the user is initially
    // loading Comms' inbox they won't see these drafts appear until that subscription is created.
    // But in this case we're deciding that that is ok.
    fetchStrategy: "cache",
  });

  const { isDefaultInboxSection } = useIsDefaultInboxSection(inboxSectionId);

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        // {
        //   label: "Add inbox section",
        //   callback() {
        //     navigateService("/inbox/new");
        //   },
        // },
      ];

      // commands.push(
      //   {
      //     label: "Edit inbox section",
      //     callback() {
      //       if (isDefaultInboxSection) {
      //         toast("vanilla", {
      //           subject: "Oops, you can't currently edit the default inbox.",
      //           description: `
      //             You can only edit custom inboxes.
      //           `,
      //         });

      //         return;
      //       }

      //       navigateService(`/inbox/${inboxSectionId}/edit`);
      //     },
      //   },
      //   {
      //     label: "Delete inbox section",
      //     callback() {
      //       if (isDefaultInboxSection) {
      //         toast("vanilla", {
      //           subject: "Oops, you can't currently delete the default inbox.",
      //           description: `
      //             You can only delete custom inboxes.
      //           `,
      //         });

      //         return;
      //       }

      //       const confirmed = confirm("Are you sure you want to delete this inbox section?");

      //       if (!confirmed) {
      //         return;
      //       }

      //       navigateService("/inbox");

      //       deleteInboxSection(environment, {
      //         inboxSectionId,
      //         afterUndo() {
      //           navigateService(`/inbox/${inboxSectionId}`);
      //         },
      //       });
      //     },
      //   },
      // );

      if (!focusedEntry) {
        return [
          ...commands,
          markDoneCommand({
            callback: () => {
              toast("vanilla", {
                subject: "Oops, no message is focused.",
                description: `
                  You first need to focus a message by hovering your mouse  
                  over it or by using the arrow keys on your keyboard.
                `,
              });
            },
          }),
        ];
      }

      if (focusedEntry.table === "draft") {
        return [
          ...commands,
          deleteDraftCommand({
            callback: () => {
              deleteDraft(environment, {
                draftId: focusedEntry.id,
                currentUserId,
              });
            },
          }),
        ];
      }

      commands.push(
        markDoneCommand({
          callback: () => {
            triageThread(environment, {
              threadId: focusedEntry.record.thread_id,
              done: true,
            });
          },
        }),
        markNotDoneCommand({
          showInKBar: false,
          callback: () => {
            toast("vanilla", {
              subject: "Message already marked not done.",
              description: `Hint: use "E" to mark as done.`,
            });
          },
        }),
        setThreadReminderCommand({
          callback: () => {
            RemindMeDialogState.open({
              threadId: focusedEntry.record.thread_id,
              fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
            });
          },
        }),
      );

      if (focusedEntry.table === "notification") {
        commands.push(
          ...threadSubscriptionCommands({
            environment,
            notification: focusedEntry.record,
            threadId: focusedEntry.record.thread_id,
          }),
        );

        if (focusedEntry.record.has_reminder) {
          commands.push(
            removeThreadReminderCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedEntry.record.thread_id,
                  triagedUntil: null,
                });
              },
            }),
          );
        }

        if (focusedEntry.record.is_starred) {
          commands.push(
            unstarThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedEntry.record.thread_id,
                  isStarred: false,
                });
              },
            }),
          );
        } else {
          commands.push(
            starThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedEntry.record.thread_id,
                  isStarred: true,
                });
              },
            }),
          );
        }
      }

      if (doesFocusedNotificationHaveADraft) {
        commands.push(
          deleteDraftCommand({
            callback: async () => {
              const [drafts] = await environment.recordLoader.getDrafts(
                { threadId: focusedEntry.record.thread_id, currentUserId },
                // In the environment we create and maintain a subscription to all of the user's drafts.
                // For this reason, we can use a cache-only fetch strategy here. Note that the environment
                // creates a subscription to the user's drafts after a delay, so if the user is initially
                // loading Comms' inbox they won't see these drafts appear until that subscription is created.
                // But in this case we're deciding that that is ok and this is also the way that the InboxEntry
                // component detects if drafts are present for a given notification.
                { fetchStrategy: "cache" },
              );

              await Promise.all(
                drafts.map((draft) => {
                  return deleteDraft(environment, {
                    draftId: draft.id,
                    currentUserId,
                  });
                }),
              );
            },
          }),
        );
      }

      return commands;
    },
    deps: [
      focusedEntry,
      doesFocusedNotificationHaveADraft,
      inboxSectionId,
      isDefaultInboxSection,
      currentUserId,
      environment,
    ],
  });

  return null;
};

/* -----------------------------------------------------------------------------------------------*/

const InboxEntriesLocationStateKey = "InboxEntries";

function useInboxEntriesLocationState() {
  return useLocationState<{ inboxEntriesLimit: number }>(InboxEntriesLocationStateKey);
}

async function onInboxEntrySelect(
  props: {
    event: IListOnEntryActionEvent<TInboxEntry>;
    inboxEntriesLimit: number;
  },
  options?: INavigateServiceOptions,
) {
  const { event, inboxEntriesLimit } = props;

  // We need to persist the current inbox entries limit in the navigation history state
  // so that, on back, we ensure that the ContentList component refocuses the correct entry.
  updateNavigationHistoryState({
    [InboxEntriesLocationStateKey]: { inboxEntriesLimit },
  });

  switch (event.entry.table) {
    case "notification": {
      onNotificationSelectNavigateToThread({ event: event.event, id: event.id, entry: event.entry }, options);
      return;
    }
    case "draft": {
      onDraftsEntrySelect({ event: event.event, id: event.id, entry: event.entry }, options);
      return;
    }
    default: {
      throw new UnreachableCaseError(event.entry);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

function useInboxSubsectionCount(inboxSectionId: string | null | undefined) {
  const [subsectionIds] = useInboxSubsectionIds(inboxSectionId);
  return subsectionIds.length;
}
