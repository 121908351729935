import { ADMIN_USER_ID } from "libs/shared-constants";
import { combineLatest, debounceTime, map, Observable, of, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export type ObserveUserIdsSubscribedToThreadResult = [string[], { isLoading: boolean; error?: unknown }];

/**
 * Returns the IDs of users who are subscribed to or participating in a thread (and hence implicitely
 * subscribed).
 */
export function observeUserIdsSubscribedToThread(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { threadId: string },
): Observable<ObserveUserIdsSubscribedToThreadResult> {
  const participantUsers = environment.recordLoader
    .observeGetThreadUserParticipants({ thread_id: props.threadId })
    .pipe(
      map(([participants, meta]) => {
        return { userIds: participants.map((s) => s.user_id), meta };
      }),
    );

  const threadSubscriberUsers = environment.recordLoader
    .observeGetThreadSubscriptions({ thread_id: props.threadId })
    .pipe(
      map(([subscriptions, meta]) => {
        return { userIds: subscriptions.filter((s) => s.preference === "all").map((s) => s.user_id), meta };
      }),
    );

  const tagSubscriberUsers = environment.recordLoader
    .observeGetThreadGroupPermissions({ thread_id: props.threadId })
    .pipe(
      switchMap(([groupPermissions, meta]) => {
        if (groupPermissions.length === 0) {
          return of({ userIds: [], meta });
        }

        return combineLatest(
          groupPermissions.map((p) =>
            environment.recordLoader.observeGetTagSubscriberUsers(
              { tagId: p.group_id, subscriptionPreference: "all" },
              meta,
            ),
          ),
        ).pipe(
          map((results) => {
            return results.reduce(
              (store, [users, meta]) => {
                store.userIds.push(...users.map((u) => u.id));
                store.meta.isLoading = store.meta.isLoading || meta.isLoading;
                store.meta.error = store.meta.error ?? meta.error;
                return store;
              },
              { userIds: [] as string[], meta: { isLoading: false, error: undefined as unknown } },
            );
          }),
        );
      }),
    );

  return combineLatest([participantUsers, threadSubscriberUsers, tagSubscriberUsers]).pipe(
    debounceTime(50),
    map(([participantUsers, threadSubscriberUsers, tagSubscriberUsers]) => {
      const userIds = new Set([
        ...participantUsers.userIds,
        ...threadSubscriberUsers.userIds,
        ...tagSubscriberUsers.userIds,
      ]);

      userIds.delete(ADMIN_USER_ID);

      const meta = {
        isLoading:
          participantUsers.meta.isLoading || threadSubscriberUsers.meta.isLoading || tagSubscriberUsers.meta.isLoading,
        error: participantUsers.meta.error ?? threadSubscriberUsers.meta.error ?? tagSubscriberUsers.meta.error,
      };

      return [Array.from(userIds), meta];
    }),
  );
}
