import { isEqual } from "libs/predicates";
import { DraftGroupRecipientDoc } from "libs/schema";
import { uniq } from "lodash-comms";
import { of, map, switchMap, combineLatest, distinctUntilChanged } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { observeTagIndirectGroupMemberIds } from "./observeTagIndirectGroupMemberIds";

/**
 * @returns looking just at the `to`field of a draft, this observable will return the group ids
 *   of all groups directly included in the draft as well as all groups which are members of those
 *   groups, recursively.
 */
export function observeExpandedDraftToFieldGroupIds(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { draftId: string },
) {
  return environment.recordLoader.observeGetRecord("draft", props.draftId).pipe(
    map(([draft, draftMeta]) => {
      return {
        to: draft?.to || [],
        draftMeta,
      };
    }),
    distinctUntilChanged(isEqual),
    switchMap(({ to, draftMeta }) => {
      const groupRecipients = to.filter((recipient): recipient is DraftGroupRecipientDoc => recipient.type === "GROUP");

      if (groupRecipients.length === 0) {
        return of({ groupIds: [], meta: draftMeta });
      }

      return combineLatest(
        groupRecipients.map((recipient) => {
          return observeTagIndirectGroupMemberIds(environment, { tagId: recipient.group_id });
        }),
      ).pipe(
        map((results) => {
          const { groupIds, meta } = results.reduce(
            (store, [groupIds, meta]) => {
              store.groupIds.push(...groupIds);
              store.meta.isLoading = store.meta.isLoading || meta.isLoading;
              store.meta.error = store.meta.error ?? meta.error;
              return store;
            },
            { groupIds: groupRecipients.map((r) => r.group_id), meta: draftMeta },
          );

          return { groupIds: uniq(groupIds), meta };
        }),
      );
    }),
  );
}
