import { uniq } from "lodash-comms";
import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ObserveOptions } from "~/environment/RecordLoader";
import { observeTagIndirectGroupMemberIds } from "./observeTagIndirectGroupMemberIds";

export type ObserveTagIndirectUserMembersIdsResult = [string[], { isLoading: boolean; error?: unknown }];

/**
 * @returns an observable of all the direct user members of this tag as well as the user members of
 *   all groups which are members of this tag, recursive.
 */
export function observeTagIndirectUserMemberIds(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { tagId: string },
  options?: ObserveOptions,
): Observable<ObserveTagIndirectUserMembersIdsResult> {
  return observeTagIndirectGroupMemberIds(environment, props, options).pipe(
    switchMap(([memberGroupIds, meta]) => {
      const tagIds = uniq([props.tagId, ...memberGroupIds]);

      return combineLatest(
        tagIds.map((tagId) => environment.recordLoader.observeGetTagUserMembers({ tag_id: tagId })),
      ).pipe(
        map((results): ObserveTagIndirectUserMembersIdsResult => {
          const { userIds, ...reducedMeta } = results.reduce(
            (store, [userMember, meta]) => {
              store.userIds.push(...userMember.map((m) => m.user_id));
              store.isLoading = store.isLoading || meta.isLoading;
              store.error = store.error ?? meta.error;
              return store;
            },
            { userIds: [] as string[], error: meta.error, isLoading: meta.isLoading },
          );

          return [userIds, reducedMeta];
        }),
      );
    }),
  );
}
