import { stringComparer } from "libs/comparers";
import { RecordValue } from "libs/schema";
import { getPointer } from "libs/schema/helpers";
import { map, Observable, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { observeUserIdsSubscribedToThread } from "./observeUserIdsSubscribedToThread";

export type ObserveUsersSubscribedToThreadResult = [
  RecordValue<"user_profile">[],
  { isLoading: boolean; error?: unknown },
];

/**
 * Returns users who are subscribed to or participating in a thread (and hence implicitely
 * subscribed).
 */
export function observeUsersSubscribedToThread(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { threadId: string },
): Observable<ObserveUsersSubscribedToThreadResult> {
  return observeUserIdsSubscribedToThread(environment, props).pipe(
    switchMap(([userIds, meta]) => {
      return environment.recordLoader.observeGetRecords(
        userIds.map((id) => getPointer("user_profile", id)),
        meta,
      );
    }),
    map(
      ([profiles, meta]): ObserveUsersSubscribedToThreadResult => [
        profiles.map((p) => p.record).sort((a, b) => stringComparer(a.name, b.name) || stringComparer(a.id, b.id)),
        meta,
      ],
    ),
  );
}
