import { ClientRecordLoaderObserveGetRecordResultMeta } from "~/environment/RecordLoader";
import { useOrganizationUserMember } from "./useOrganizationUserMember";
import { isDefined } from "libs/predicates";

export type UseIsUserDeletedFromOrgResult = [boolean, ClientRecordLoaderObserveGetRecordResultMeta];

export function useIsUserDeletedFromOrg(props: {
  userId: string | null | undefined;
  organizationId: string | null | undefined;
}): UseIsUserDeletedFromOrgResult {
  const [organizationUserMember, meta] = useOrganizationUserMember({
    organizationId: props.organizationId,
    userId: props.userId,
    includeSoftDeletes: true,
  });

  const isDeleted =
    !!organizationUserMember?.deleted_at || (!meta.isLoading && !isDefined(meta.error) && !organizationUserMember);

  return [isDeleted, meta];
}
