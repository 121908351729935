import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";

export type UseOrganizationDeletedUserIdsResult = UseRecordLoaderResult<string[]>;

export function useOrganizationDeletedUserIds(props: {
  organizationId: string | null | undefined;
}): UseOrganizationDeletedUserIdsResult {
  const initialLimit = 1000;

  return useRecordLoader({
    name: "useOrganizationUserIds",
    load({ loader, limit, currentUserId, deps: [organizationId] }) {
      if (!organizationId) {
        return loader.createObserveQueryResult<"user_profile">();
      }

      return loader.observeGetOrganizationUsers({
        currentUserId,
        organizationId: organizationId,
        isDeleted: true,
        limit,
      });
    },
    deps: [props.organizationId],
    initialLimit,
    limitStep: Math.ceil(initialLimit / 2),
    map: (records) => records.map((r) => r.id),
  });
}
