import { Observable, combineLatest, map, switchMap } from "rxjs";
import {
  RecordValue,
  TagSubscriptionPreference,
  generateRecordId,
  getPointer,
  getSubscriptionPreferencePriority,
} from "libs/schema";
import { ClientRecordLoaderObserveQueryResultMeta } from "~/environment/RecordLoader";
import { uniq } from "lodash-es";
import { useObservable, useObservableState } from "observable-hooks";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { numberComparer, stringComparer } from "libs/comparers";

export type TagSubscriberDoc = {
  userProfile: RecordValue<"user_profile">;
  subscriptionPreference: TagSubscriptionPreference;
};

export type UseTagSubscribersResult = [TagSubscriberDoc[], { isLoading: boolean; error?: unknown }];

export function useTagSubscribers(props: { tagId: string | null | undefined }): UseTagSubscribersResult {
  const environment = useClientEnvironment();

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([tagId, environment]) => {
          const loader = environment.recordLoader;

          if (!tagId) {
            return loader.createObserveQueryResult<"user_profile">() as unknown as Observable<
              [TagSubscriberDoc[], ClientRecordLoaderObserveQueryResultMeta]
            >;
          }

          return combineLatest([
            loader.observeGetTagUserMembers({ tag_id: tagId }),
            loader.observeGetTagSubscriptions({ tag_id: tagId }),
          ]).pipe(
            switchMap(([[tagUserMembers, tagUserMembersMeta], [tagSubscriptions, tagSubscriptionsMeta]]) => {
              const userIds = uniq([
                ...tagUserMembers.map((record) => record.user_id),
                ...tagSubscriptions.map((record) => record.user_id),
              ]);

              return loader
                .observeGetRecords(
                  userIds.map((id) => getPointer("user_profile", id)),
                  {
                    isLoading: tagUserMembersMeta.isLoading || tagSubscriptionsMeta.isLoading,
                    error: tagUserMembersMeta.error ?? tagSubscriptionsMeta.error,
                  },
                )
                .pipe(
                  map(([userProfiles, meta]): UseTagSubscribersResult => {
                    const data = userProfiles
                      .map(({ record }) => {
                        const subscription = tagSubscriptions.find(
                          ({ id }) =>
                            id ===
                            generateRecordId("tag_subscription", {
                              tag_id: tagId,
                              user_id: record.id,
                            }),
                        );

                        return {
                          userProfile: record,
                          subscriptionPreference: subscription?.preference || "involved",
                        };
                      })
                      .sort((a, b) => {
                        const x = getSubscriptionPreferencePriority(a.subscriptionPreference);
                        const y = getSubscriptionPreferencePriority(b.subscriptionPreference);

                        return (
                          numberComparer(x, y) ||
                          stringComparer(a.userProfile.name, b.userProfile.name) ||
                          stringComparer(a.userProfile.id, b.userProfile.id)
                        );
                      });

                    return [data, meta];
                  }),
                );
            }),
          );
        }),
      ),
    [props.tagId, environment],
  );

  return useObservableState(observable, DEFAULT_VALUE);
}

const DEFAULT_VALUE = Object.freeze([
  [] as TagSubscriberDoc[],
  { isLoading: true } as { isLoading: boolean; error?: unknown },
]) as UseTagSubscribersResult;
