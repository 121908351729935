import Mention, { MentionOptions } from "@tiptap/extension-mention";
import tippy, { Instance, GetReferenceClientRect } from "tippy.js";
import { mergeAttributes, NodeConfig, ReactRenderer } from "@tiptap/react";
import { MentionDropdown, MentionDropdownItem } from "./MentionDropdown";
import { useEffect } from "react";
import { PluginKey } from "@tiptap/pm/state";
import { SuggestionEntryComponent } from "../utils";
import commandScore from "command-score";
import { throwUnreachableCaseError } from "libs/errors";
import { numberComparer, stringComparer } from "libs/comparers";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { getMentionableUsers } from "~/queries/getMentionableUsers";
import { MentionableUser } from "~/observables/observeMentionableUsers";
import { MentionableGroup } from "~/observables/observeMentionableGroups";
import { applyAdditionalMentionSuggestionWeights } from "~/components/forms/tiptap/suggestion-utils";
import { getMentionableGroups } from "~/queries/getMentionableGroups";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { RecordPointer } from "libs/schema";
import { GetOptions } from "~/environment/RecordLoader";
import { observeMentionExtensionData } from "./observeMentionExtensionData";

/**
 * This hook maintains proper subscriptions to make opening the mentions list speedy.
 */
export function useMentionExtensionSubscriptions() {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  useEffect(() => {
    const disposable = environment.isLoading.add();

    const sub = observeMentionExtensionData(environment, { currentUserId }).subscribe((isLoading) => {
      if (isLoading) return;
      disposable[Symbol.dispose]();
    });

    // As the query promises are cached by fetch strategy, we need to create additional subscriptions to the
    // mentions using the cache fetch strategy, otherwise it will always load the data from the persisted DB.
    sub.add(observeMentionExtensionData(environment, { currentUserId }, { fetchStrategy: "cache-first" }).subscribe());

    return () => {
      disposable[Symbol.dispose]();
      sub.unsubscribe();
    };
  }, [environment, currentUserId]);
}

export function buildMentionExtension(props: {
  environmentRef: React.MutableRefObject<Pick<ClientEnvironment, "auth" | "recordLoader">>;
  char: "@" | "@@" | "@@@";
  pluginKey?: PluginKey;
  extend?: Partial<NodeConfig<MentionOptions, any>>;
}) {
  const { environmentRef, char, pluginKey, extend } = props;
  const extensionId = `mention-${char}`;
  const priority =
    char === "@" ? 300
    : char === "@@" ? 200
    : 100;

  return Mention.extend({
    name: extensionId,
    addAttributes() {
      return {
        ...this.parent?.(),

        subject: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-subject"),
          renderHTML: (attributes) => {
            if (!attributes.subject) {
              return {};
            }

            return {
              "data-subject": attributes.subject,
            };
          },
        },

        priority: {
          default: priority,
          renderHTML: () => {
            return {
              "data-priority": priority,
            };
          },
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: `span[data-type="mention"][data-priority="${priority}"]`,
        },
      ];
    },
    renderHTML({ node, HTMLAttributes }) {
      return [
        "span",
        mergeAttributes({ "data-type": "mention" }, this.options.HTMLAttributes, HTMLAttributes),
        this.options.renderHTML?.({
          options: this.options,
          node,
        }),
      ];
    },
    ...extend,
  }).configure({
    renderHTML({ node }) {
      const prefix = char.split("").fill("@").join("");

      const label = node.attrs.icon ? `${node.attrs.icon} ${node.attrs.label}` : node.attrs.label;
      return `${prefix}${label}`;
    },
    suggestion: {
      pluginKey: pluginKey ?? new PluginKey(extensionId),
      char,
      allowedPrefixes: [" ", ":"],
      items: async ({ query, editor }) => {
        const environment = environmentRef.current;
        const currentUserId = environment.auth.getAndAssertCurrentUserId();

        // We can use the cached data here as we subscribe to the queries below with the
        // useMentionExtensionSubscriptions hook
        const options: GetOptions = { fetchStrategy: "cache-first" };
        const [users, groups, [settings]] = await Promise.all([
          getMentionableUsers(environment, options),
          getMentionableGroups(environment, options),
          environment.recordLoader.getRecord({ table: "user_settings", id: currentUserId }, options),
        ]);

        const results = {
          people: [] as MentionableUser[],
          groups: [] as MentionableGroup[],
        };

        const mentions = settings?.mention_frequency || {};

        const mentionWeightAdjustments: Record<string, number> = editor.storage.mention?.mentionWeightAdjustments || {};

        const filterItems = <T extends { type: "group" | "user"; id: string }>(
          items: T[],
          query: string,
          getLabel: (o: T) => string,
        ) =>
          items
            .map((c) => {
              const label = getLabel(c);
              let score = commandScore(label, query);

              if (score !== 0) {
                const pointer: RecordPointer =
                  c.type === "group" ? { table: "tag", id: c.id }
                  : c.type === "user" ? { table: "user_profile", id: c.id }
                  : throwUnreachableCaseError(c.type);

                score = applyAdditionalMentionSuggestionWeights({
                  score,
                  pointer,
                  frequencyDictionary: mentions,
                  mentionWeightAdjustments,
                });
              }

              return {
                option: c,
                score,
              };
            })
            .sort((a, b) => b.score - a.score)
            .slice(0, 9)
            .filter((r) => r.score > 0)
            .map((r) => ({ ...r.option }));

        if (query.startsWith("#")) {
          results.groups = filterItems(groups, query.slice(1), (c) => c.record.name);
        } else if (query) {
          results.people = filterItems(users, query, (m) => m.profile.name);
          results.groups = filterItems(groups, query, (c) => c.record.name);
        } else {
          results.people = users
            .map((user) => {
              const pointer: RecordPointer = {
                table: "user_profile",
                id: user.id,
              };

              return {
                user,
                score: applyAdditionalMentionSuggestionWeights({
                  score: 0,
                  pointer,
                  frequencyDictionary: mentions,
                  mentionWeightAdjustments,
                }),
              };
            })
            .sort(
              (a, b) =>
                numberComparer(b.score, a.score) ||
                stringComparer(a.user.profile.name, b.user.profile.name) ||
                stringComparer(a.user.id, b.user.id),
            )
            .slice(0, 5)
            .map((m) => m.user);

          results.groups = groups
            .map((group) => {
              const pointer: RecordPointer = {
                table: "tag",
                id: group.id,
              };

              return {
                group,
                score: applyAdditionalMentionSuggestionWeights({
                  score: 0,
                  pointer,
                  frequencyDictionary: mentions,
                  mentionWeightAdjustments,
                }),
              };
            })
            .sort(
              (a, b) =>
                numberComparer(b.score, a.score) ||
                stringComparer(a.group.record.name, b.group.record.name) ||
                stringComparer(a.group.id, b.group.id),
            )
            .slice(0, 5)
            .map((m) => m.group);
        }

        const items: MentionDropdownItem[] = [...results.people, ...results.groups].map((o, index) => ({
          ...o,
          index,
        }));

        return items;
      },

      render: () => {
        let component: SuggestionEntryComponent<{}> | undefined;
        let popup: Instance[] = [];

        return {
          onStart: (props) => {
            component = new ReactRenderer(MentionDropdown, {
              props,
              editor: props.editor,
            });

            if (!props.clientRect) {
              return;
            }

            popup = tippy("body", {
              getReferenceClientRect: props.clientRect as GetReferenceClientRect,
              appendTo: () => document.body,
              content: component.element,
              showOnCreate: true,
              interactive: true,
              trigger: "manual",
              placement: "bottom-start",
              zIndex: 150,
            });
          },

          onUpdate(props) {
            component?.updateProps(props);

            if (!props.clientRect) {
              return;
            }

            popup[0]?.setProps({
              getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            });
          },

          onKeyDown(props) {
            if (props.event.key === "Escape") {
              component?.destroy();
              props.event.stopPropagation();
              return true;
            }

            return component?.ref?.onKeyDown(props) || false;
          },

          onExit() {
            popup[0]?.destroy();
            component?.destroy();
          },
        };
      },
    },
  });
}
