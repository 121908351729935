import { ComponentType, useRef } from "react";
import { List, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { useParams } from "react-router-dom";
import { NotFound } from "~/components/NotFound";
import { ESCAPE_TO_BACK_COMMAND, getCommandFactory, inviteMembersToOrganizationCommand } from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { useOrganizationProfile } from "~/hooks/useOrganizationProfile";
import { useOrganizationUserInvitations } from "~/hooks/useOrganizationUserInvitations";
import { ContentList, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { PointerWithRecord } from "libs/schema";
import { useUserProfile } from "~/hooks/useUserProfile";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { entryCSSClasses } from "~/components/content-list/layout";
import { useUserContactInfo } from "~/hooks/useUserContactInfo";
import { useOrganizationUserIds } from "~/hooks/useOrganizationUserIds";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useIsUserOrganizationAdmin } from "~/hooks/useIsUserOrganizationAdmin";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { LabelChip, LabelContainer } from "~/components/LabelChip";
import { useOrganizationUserInvitation } from "~/hooks/useOrganizationUserInvitation";
import IconButton from "@mui/material/IconButton";
import { IoMdTrash } from "react-icons/io";
import { deleteUserInvitation, revokeUserInvitation } from "~/actions/invitations";
import { reactivateUserForOrg, removeUserFromOrg } from "~/actions/organization";
import { MdPersonOff } from "react-icons/md";
import { cx } from "@emotion/css";
import { useIsOnline } from "~/hooks/useIsOnline";
import { useCallbackAt } from "~/hooks/useCallbackAt";
import { Tooltip } from "~/components/Tooltip";
import { useOrganizationDeletedUserIds } from "~/hooks/useOrganizationDeletedUserIds";
import { map, Observable } from "rxjs";
import { AssertUnreachable } from "libs/errors";
import { SetOptional } from "type-fest";
import { toast } from "~/environment/toast-service";
import { OutlineButton } from "~/components/OutlineButtons";
import { useOrganizationUserMember } from "~/hooks/useOrganizationUserMember";

type FocusedEntry = PointerWithRecord<"organization_user_member"> | PointerWithRecord<"organization_user_invitation">;

export const MembersView: ComponentType<{}> = () => {
  const params = useParams();
  const organizationId = params.organizationId;

  const { currentUserId } = useAuthGuardContext();

  const [organization, { isLoading: isOrganizationLoading }] = useOrganizationProfile(organizationId);

  const [isCurrentUserAdmin] = useIsUserOrganizationAdmin({
    userId: currentUserId,
    organizationId,
  });

  const { setFocusedEntry, focusedEntry$ } = useKBarAwareFocusedEntry$<FocusedEntry>();

  // We're currently fetching all members and deleted members without paging. This is
  // so that we can render the list of members and deleted members in a single list without
  // needing to handle paging for the undeleted and deleted members separately.
  const [memberUserIds] = useOrganizationUserIds({
    organizationId: organizationId,
  });

  const [deletedMemberUserIds] = useOrganizationDeletedUserIds({
    organizationId: organizationId,
  });

  const [organizationUserInvites] = useOrganizationUserInvitations({
    organizationId: organizationId,
  });

  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  useRegisterMemberCommands({ focusedEntry$, organizationId });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [!!organization],
  });

  if (!organizationId || !organization) {
    if (isOrganizationLoading) {
      return <div>Loading...</div>;
    }

    return <NotFound title="Organization Not Found" />;
  }

  return (
    <>
      <Helmet>
        <title>{organization.name} members | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef} className="flex-col">
        <div className="flex items-center">
          <h1 className="text-3xl">{organization.name} members</h1>
          <div className="flex-1" />
        </div>

        <MainLayout.HeaderMenu>
          <li>
            <OutlineButton onClick={inviteMembersToOrganizationCommand.trigger}>
              <span className="text-[12.8px]">Add members</span>
            </OutlineButton>
          </li>
        </MainLayout.HeaderMenu>
      </MainLayout.Header>

      <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
        <ContentList<FocusedEntry> className="mb-20" autoFocus onEntryFocused={setFocusedEntry}>
          {memberUserIds.map((userId, index) => (
            <MemberEntry
              key={userId}
              organizationId={organizationId}
              userId={userId}
              isCurrentUserAdmin={isCurrentUserAdmin}
              relativeOrder={index}
            />
          ))}

          {organizationUserInvites.length > 0 && (
            <>
              <div className="mx-12 mt-8 mb-2 uppercase font-bold text-sm">Pending Invitations</div>

              {organizationUserInvites.map((inviteId, index) => (
                <InvitationEntry key={inviteId} organizationInvitationId={inviteId} relativeOrder={index} />
              ))}
            </>
          )}

          {deletedMemberUserIds.length > 0 && (
            <>
              <div className="mx-12 mt-8 mb-2 uppercase font-bold text-sm">Removed Members</div>

              {deletedMemberUserIds.map((userId, index) => (
                <RemovedMemberEntry
                  key={userId}
                  organizationId={organizationId}
                  userId={userId}
                  isCurrentUserAdmin={isCurrentUserAdmin}
                  relativeOrder={index}
                />
              ))}
            </>
          )}
        </ContentList>
      </ListScrollbox>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const MemberEntry: ComponentType<{
  organizationId: string;
  userId: string;
  isCurrentUserAdmin: boolean;
  relativeOrder: number;
}> = (props) => {
  const { currentUserId } = useAuthGuardContext();
  const [member] = useOrganizationUserMember({
    userId: props.userId,
    organizationId: props.organizationId,
  });

  const [memberProfile] = useUserProfile(props.userId);
  const [memberContactInfo] = useUserContactInfo(props.userId);

  const [memberIsAdmin] = useIsUserOrganizationAdmin({
    userId: props.userId,
    organizationId: props.organizationId,
  });

  const canDeleteUser = props.isCurrentUserAdmin && currentUserId !== props.userId;

  const entryData = useAsPointerWithRecord("organization_user_member", member);

  if (!memberProfile || !entryData) return null;

  return (
    <List.Entry<PointerWithRecord<"organization_user_member">>
      id={memberProfile.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
    >
      <div className={cx("OrganizationMemberEntry", entryCSSClasses)}>
        <div className="w-full flex items-center">
          <span className={`flex-1`}>
            {memberProfile.name}{" "}
            {memberIsAdmin ?
              <span className="p-1 px-2 border rounded text-xs border-slate-10 text-slate-10 ml-1">Admin</span>
            : null}
          </span>

          {memberContactInfo && <span className="flex-[3] text-slate-9">{memberContactInfo.email_address}</span>}

          <LabelContainer>
            {canDeleteUser && (
              <LabelChip
                colorClassName="invisible group-hover:visible group-focus:visible !px-2 !border-red-8 !text-sm !text-red-8 hover:pointer hover:!text-red-11 hover:!border-red-11 z-100"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteOrganizationMemberCommand.trigger();
                }}
              >
                <div className="flex items-center">
                  <MdPersonOff />
                  <span className="ml-1">Remove</span>
                </div>
              </LabelChip>
            )}
          </LabelContainer>
        </div>
      </div>
    </List.Entry>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const RemovedMemberEntry: ComponentType<{
  organizationId: string;
  userId: string;
  isCurrentUserAdmin: boolean;
  relativeOrder: number;
}> = (props) => {
  const [member] = useOrganizationUserMember({
    userId: props.userId,
    organizationId: props.organizationId,
    includeSoftDeletes: true,
  });

  const [memberProfile] = useUserProfile(props.userId);
  const [memberContactInfo] = useUserContactInfo(props.userId);
  const canReactivateUser = props.isCurrentUserAdmin;

  const entryData = useAsPointerWithRecord("organization_user_member", member);

  if (!memberProfile || !entryData) return null;

  return (
    <List.Entry<PointerWithRecord<"organization_user_member">>
      // When changing a member from deleted to active or vice versa, it's possible for react
      // to render the same entry as both deleted and active for a brief moment. Because of this,
      // we need to ensure that this entry's id is unique.
      id={`removed:${memberProfile.id}`}
      data={entryData}
      relativeOrder={props.relativeOrder}
    >
      <div className={cx("DeletedOrganizationMemberEntry", entryCSSClasses)}>
        <div className="w-full flex items-center">
          <span className={`flex-1`}>{memberProfile.name}</span>

          {memberContactInfo && <span className="flex-[3] text-slate-9">{memberContactInfo.email_address}</span>}

          <LabelContainer>
            {canReactivateUser && (
              <LabelChip
                colorClassName="invisible group-hover:visible group-focus:visible bg-white !px-2 !text-sm !border-slate-9 !text-slate-10 z-100"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  reactivateOrganizationMemberCommand.trigger();
                }}
              >
                Reactivate
              </LabelChip>
            )}
          </LabelContainer>
        </div>
      </div>
    </List.Entry>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const InvitationEntry: ComponentType<{
  organizationInvitationId: string;
  relativeOrder: number;
}> = (props) => {
  const [invitation] = useOrganizationUserInvitation(props.organizationInvitationId);
  const [invitedByUser] = useUserProfile(invitation?.creator_user_id);
  const isOnline = useIsOnline();

  const isExpired = useCallbackAt(
    () => (invitation?.expires_at ? invitation.expires_at <= new Date().toISOString() : false),
    [invitation?.expires_at],
  );

  const isChangePending =
    invitation ? !invitation.server_updated_at || invitation.updated_at > invitation.server_updated_at : false;

  const entryData = useAsPointerWithRecord("organization_user_invitation", invitation);

  if (!invitation || !entryData) return null;

  return (
    <List.Entry<PointerWithRecord<"organization_user_invitation">>
      id={invitation.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
    >
      <div className={cx("OrganizationUserInvitationEntry", entryCSSClasses)}>
        <div className="w-full flex items-center justify-between group">
          <div className="flex flex-1">
            <span className="flex-1 invitation-email">{invitation.email_address}</span>

            {invitedByUser && <span className="flex-[3] text-slate-9 ml-5">invited by: {invitedByUser.name}</span>}

            <LabelContainer className="mx-4 flex space-x-2">
              {isExpired && !isChangePending && (
                <LabelChip
                  colorClassName={cx(`bg-red-8 border-red-10 text-white`)}
                  tooltip="This invitation has expired. The user will need to be re-invited."
                >
                  Expired
                </LabelChip>
              )}

              {
                // If the record has been deleted then this component won't be rendered. So a pending
                // change is always a revocation.
                isChangePending && (
                  <LabelChip
                    colorClassName={cx(`bg-red-8 border-red-10 text-white`)}
                    tooltip={
                      isOnline ? `Processing request...` : (
                        `Comms is currently offline. This revocation will be processed when Comms is back online.`
                      )
                    }
                  >
                    Revoking...
                  </LabelChip>
                )
              }
            </LabelContainer>
          </div>

          <div className="space-x-2">
            {!isExpired && (
              <LabelChip
                colorClassName="invisible group-hover:visible group-focus:visible !px-2 !border-red-8 !text-sm !text-red-8 hover:pointer hover:!text-red-11 hover:!border-red-11 z-100"
                tooltip="Revoke invitation"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  revokeUserInvitationCommand.trigger();
                }}
              >
                <div className="flex items-center">
                  <MdPersonOff />
                  <span className="ml-1">Revoke</span>
                </div>
              </LabelChip>
            )}

            {isExpired && !isChangePending && (
              <Tooltip side="bottom" content="Delete invitation">
                <IconButton
                  aria-label="delete-invitation"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteUserInvitationCommand.trigger();
                  }}
                  className="invisible group-hover:visible group-focus:visible"
                  size="small"
                >
                  <IoMdTrash />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </List.Entry>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useRegisterMemberCommands(props: {
  focusedEntry$: Observable<FocusedEntry | null>;
  organizationId: string | null | undefined;
}) {
  const { focusedEntry$, organizationId } = props;
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands() {
      return focusedEntry$.pipe(
        map((focusedEntry) => {
          const commands: ICommandArgs[] = [ESCAPE_TO_BACK_COMMAND];

          if (!organizationId) return commands;

          if (focusedEntry) {
            switch (focusedEntry.table) {
              case "organization_user_member": {
                const member = focusedEntry.record;

                if (member.deleted_at) {
                  commands.push(
                    reactivateOrganizationMemberCommand({
                      callback: async () => {
                        if (!environment.network.isOnline()) {
                          toast("vanilla", {
                            subject: "Not supported in offline mode",
                            description: "Can't reactivate members when offline.",
                          });

                          return;
                        }

                        await reactivateUserForOrg(environment, {
                          userId: member.user_id,
                          organizationId,
                        });
                      },
                    }),
                  );
                } else {
                  commands.push(
                    deleteOrganizationMemberCommand({
                      callback: async () => {
                        if (!environment.network.isOnline()) {
                          toast("vanilla", {
                            subject: "Not supported in offline mode",
                            description: "Can't remove members when offline.",
                          });

                          return;
                        }

                        await removeUserFromOrg(environment, {
                          userId: member.user_id,
                          organizationId,
                        });
                      },
                    }),
                  );
                }

                break;
              }
              case "organization_user_invitation": {
                const invitation = focusedEntry.record;
                const isExpired = invitation.expires_at <= new Date().toISOString();
                const isChangePending =
                  !invitation.server_updated_at || invitation.updated_at > invitation.server_updated_at;

                if (!isExpired) {
                  commands.push(
                    revokeUserInvitationCommand({
                      callback: async () => {
                        // We reevaluate the expiration status because `new Date().toISOString()` above won't
                        // automatically update.
                        const isExpired = invitation.expires_at <= new Date().toISOString();
                        if (isExpired) return;
                        revokeUserInvitation(environment, invitation);
                      },
                    }),
                  );
                }

                if (isExpired && !isChangePending) {
                  commands.push(
                    deleteUserInvitationCommand({
                      callback: async () => {
                        deleteUserInvitation(environment, invitation);
                      },
                    }),
                  );
                }

                break;
              }
              default: {
                // Type only check to ensure that we've handled all cases.
                type A = AssertUnreachable<(typeof focusedEntry)["table"]>;
              }
            }
          } else {
          }

          return commands;
        }),
      );
    },
    deps: [organizationId, focusedEntry$],
  });
}

/* -----------------------------------------------------------------------------------------------*/

const deleteOrganizationMemberCommand = getCommandFactory(
  "DELETE_ORGANIZATION_MEMBER",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    label: "Remove user from organization",
    keywords: ["remove member", "delete user"],
    ...options,
  }),
);

const reactivateOrganizationMemberCommand = getCommandFactory(
  "REACTIVATE_ORGANIZATION_MEMBER",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    label: "Reactivate member",
    keywords: ["reactivate membership", "reactivate user", "add member"],
    ...options,
  }),
);

const revokeUserInvitationCommand = getCommandFactory(
  "REVOKE_USER_INVITATION",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    label: "Revoke invitation",
    ...options,
  }),
);

const deleteUserInvitationCommand = getCommandFactory(
  "DELETE_USER_INVITATION",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => ({
    label: "Delete invitation",
    ...options,
  }),
);

/* -----------------------------------------------------------------------------------------------*/
