import { css, cx } from "@emotion/css";
import React, { useState, useEffect, forwardRef, useImperativeHandle, ComponentType, useMemo } from "react";
import { SuggestionProps } from "@tiptap/suggestion";
import { onSuggestionDialogClose, onSuggestionDialogOpen } from "../utils";
import { Avatar } from "~/components/Avatar";
import { BsLockFill } from "react-icons/bs";
import { ThreadVisibility } from "libs/schema";
import { MentionableUser } from "~/observables/observeMentionableUsers";
import { MentionableGroup } from "~/observables/observeMentionableGroups";
import { throwUnreachableCaseError, UnreachableCaseError } from "libs/errors";
import { useEditorMentionContext } from "./context";
import { renderGroupName } from "~/utils/groups-utils";

/**
 * This code was largely taken from https://tiptap.dev/api/nodes/mention#usage
 */

export type MentionDropdownItem = UserMention | GroupMention;

type WithIndex<T> = T & { index: number };

type UserMention = WithIndex<MentionableUser>;

type GroupMention = WithIndex<MentionableGroup>;

interface IMentionAttributes {
  id: string;
  label: string;
  subject: string;
}

export const MentionDropdown = forwardRef<
  { onKeyDown(o: { event: KeyboardEvent }): boolean | undefined },
  SuggestionProps<MentionDropdownItem>
>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { restrictToVisibility } = useEditorMentionContext();

  useEffect(() => {
    // We need to track whether a mentioned list is opened or not
    // for the EditorOverflowHandler. See `EditorOverflowHandler.ts`
    // for more info.
    onSuggestionDialogOpen();

    return onSuggestionDialogClose;
  }, []);

  const [people, groups] = useMemo(() => {
    return props.items.reduce(
      (store, item) => {
        if (item.type === "user") {
          store[0].push(item);
        } else {
          store[1].push(item);
        }

        return store;
      },
      [[] as UserMention[], [] as GroupMention[]],
    );
  }, [props.items]);

  // sort groups by archived status
  const sortedGroups = groups
    .toSorted((a, b) => {
      if (a.record.archived_at && !b.record.archived_at) {
        return 1;
      } else if (!a.record.archived_at && b.record.archived_at) {
        return -1;
      } else {
        return 0;
      }
    })
    // and fix their index
    .map((group, index) => ({ ...group, index: index + people.length }));

  const items = [...people, ...sortedGroups].map((item, index) => ({
    ...item,
    index,
  }));

  const selectItem = (index: number) => {
    const item = items[index];

    if (!item) return;

    const mentionProps: IMentionAttributes = {
      id: item.id,
      label:
        item.type === "group" ? renderGroupName(item.record)
        : item.type === "user" ? item.profile.name
        : throwUnreachableCaseError(item),
      subject: item.type,
    };

    // The SuggestionProps are typed as requiring props.command to
    // receive the same type as props.items[0] but, in actually,
    // they don't need to be the same and we aren't passing the
    // same interface.

    props.command(mentionProps as any);
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  };

  const enterHandler = (e?: Event) => {
    const item = items[selectedIndex];

    if (!item) return;

    switch (item.type) {
      case "user": {
        selectItem(selectedIndex);
        return;
      }
      case "group": {
        const isDisabled =
          restrictToVisibility === null ? false
          : item.isPrivate ? restrictToVisibility === "SHARED"
          : restrictToVisibility === "PRIVATE";

        if (isDisabled) {
          alert(
            item.isPrivate ?
              "Can only add private groups to private threads."
            : "Can only add shared groups to shared threads.",
          );

          e?.preventDefault();
        } else {
          selectItem(selectedIndex);
        }

        return;
      }
      default: {
        throw new UnreachableCaseError(item);
      }
    }
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        event.stopPropagation();
        enterHandler(event);
        return true;
      }

      return false;
    },
  }));

  return (
    <div
      data-testid="editor-mention-dropdown"
      className={cx("bg-white rounded overflow-hidden", `w-[330px] pb-3`, mentionListStyles)}
    >
      <PeopleList people={people} selectedIndex={selectedIndex} selectItem={selectItem} />

      {people.length > 0 && groups.length > 0 && <hr className="text-slate-5 mt-3 mb-1" />}

      <GroupList
        groups={sortedGroups}
        selectedIndex={selectedIndex}
        restrictToVisibility={restrictToVisibility}
        selectItem={selectItem}
      />
    </div>
  );
});

const mentionListStyles = css`
  box-shadow:
    rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px,
    rgba(15, 15, 15, 0.2) 0px 9px 24px;
`;

const PeopleList: ComponentType<{
  people: UserMention[];
  selectedIndex: number;
  selectItem: (index: number) => void;
}> = (props) => {
  if (props.people.length === 0) return null;

  return (
    <SectionWrapper>
      <SectionHeader label="People" />

      {props.people.map((person) => (
        <Entry
          key={person.id}
          item={person}
          isSelected={props.selectedIndex === person.index}
          onClick={() => props.selectItem(person.index)}
        >
          <Avatar
            label={person.profile.name}
            photoURL={person.profile.photo_url}
            fontSize="11px"
            width="1.5rem"
            className="mx-3"
          />
          <span className="truncate">{person.profile.name}</span>
        </Entry>
      ))}
    </SectionWrapper>
  );
};

const GroupList: ComponentType<{
  groups: GroupMention[];
  selectedIndex: number;
  restrictToVisibility: ThreadVisibility | null;
  selectItem: (index: number) => void;
}> = (props) => {
  if (props.groups.length === 0) return null;

  return (
    <SectionWrapper>
      <SectionHeader label="Groups" />

      {props.groups.map((group) => {
        const isDisabled =
          props.restrictToVisibility === null ? false
          : group.isPrivate ? props.restrictToVisibility === "SHARED"
          : props.restrictToVisibility === "PRIVATE";

        return (
          <Entry
            key={group.id}
            item={group}
            isSelected={props.selectedIndex === group.index}
            isDisabled={isDisabled}
            onClick={(e) => {
              if (isDisabled) {
                alert(
                  group.isPrivate ?
                    "Can only add private groups to private threads."
                  : "Can only add shared groups to shared threads.",
                );

                e.preventDefault();
              } else {
                props.selectItem(group.index);
              }
            }}
          >
            <div className={cx(group.record.archived_at ? "text-slate-9" : null)}>
              <span className="inline-flex items-center shrink whitespace-nowrap overflow-hidden">
                <span className="text-xl mx-3 w-6 text-center">{group.record.icon}</span>
                <span className="truncate">{group.record.name}</span>
                {group.isPrivate && <BsLockFill className="ml-1 scale-75" />}
                {group.record.archived_at && <span className="ml-1"> (Archived)</span>}
              </span>
            </div>
          </Entry>
        );
      })}
    </SectionWrapper>
  );
};

const SectionWrapper: ComponentType<{}> = (props) => {
  return <div className="flex flex-col">{props.children}</div>;
};

const SectionHeader: ComponentType<{
  label: string;
}> = (props) => {
  return <h4 className="text-xs uppercase text-slate-9 font-medium mx-4 my-2">{props.label}</h4>;
};

const Entry: ComponentType<{
  item: { id: string };
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  return (
    <button
      type="button"
      className={cx(
        "flex items-center text-left mx-1 pr-3 py-1 rounded",
        "relative text-sm",
        props.isSelected ? "bg-slate-5" : "bg-transparent",
        props.isDisabled && "text-slate-9",
      )}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};
