import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  observeUsersSubscribedToThread,
  ObserveUsersSubscribedToThreadResult,
} from "~/observables/observeUsersSubscribedToThread";

export type UseUsersSubscribedToThreadResult = ObserveUsersSubscribedToThreadResult;

/**
 * Returns users who are subscribed to or participating in a thread (and hence implicitely
 * subscribed).
 */
export function useUsersSubscribedToThread(props: { threadId: string | null | undefined }) {
  const environment = useClientEnvironment();

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([threadId, environment]) => {
          if (!threadId) return of<UseUsersSubscribedToThreadResult>([[], { isLoading: false }]);
          return observeUsersSubscribedToThread(environment, { threadId });
        }),
      ),
    [props.threadId, environment],
  );

  return useObservableState(observable, DEFAULT_VALUE);
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]),
  Object.freeze({ isLoading: true }),
]) as unknown as UseUsersSubscribedToThreadResult;
