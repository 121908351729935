import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  observeNewUsersWhoWillReceiveNotificationForDraftReply,
  ObserveNewUsersWhoWillReceiveNotificationForDraftReplyResult,
} from "~/observables/observeNewUsersWhoWillReceiveNotificationForDraftReply";

export type UseNewUsersWhoWillReceiveNotificationForDraftReplyResult =
  ObserveNewUsersWhoWillReceiveNotificationForDraftReplyResult;

export function useNewUsersWhoWillReceiveNotificationForDraftReply(props: { draftId: string | null | undefined }) {
  const environment = useClientEnvironment();

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([draftId, environment]) => {
          if (!draftId) return of<UseNewUsersWhoWillReceiveNotificationForDraftReplyResult>([[], { isLoading: false }]);
          return observeNewUsersWhoWillReceiveNotificationForDraftReply(environment, { draftId });
        }),
      ),
    [props.draftId, environment],
  );

  return useObservableState(observable, DEFAULT_VALUE);
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]),
  Object.freeze({ isLoading: true }),
]) as unknown as UseNewUsersWhoWillReceiveNotificationForDraftReplyResult;
