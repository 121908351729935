import { ComponentType, memo } from "react";
import { isEqual } from "libs/predicates";
import { cx } from "@emotion/css";

export const InfoPanel: ComponentType<{
  /** The backdrop is only applicable to mobile */
  onBackdropClick?: () => void;
}> = memo((props) => {
  return (
    <>
      {/* Spacer on non-mobile since the panel itself is floating (fixed) */}
      <div className="w-[280px] shrink-0 hidden md-w:block" />

      {/* Mobile backdrop */}
      <div
        className="fixed w-screen h-screen backdrop-blur block md-w:hidden z-[49] top-0 left-0"
        onClick={props.onBackdropClick}
      />

      {/* Panel */}
      <aside
        className={cx("InfoPanel h-screen fixed right-0 top-0 z-[50] w-[280px]", "bg-white shadow-lg  overflow-y-auto")}
      >
        {props.children}
      </aside>
    </>
  );
}, isEqual);
