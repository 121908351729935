import { isOrganizationGroupTagRecord } from "libs/schema";
import { map } from "rxjs/operators";
import { getPointer } from "libs/schema";
import { GroupTagRecord } from "libs/schema";
import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ADMIN_ORGANIZATION_ID } from "libs/shared-constants";

type UseOrganizationMembersOfThreadResult = [GroupTagRecord[], { isLoading: boolean; error?: unknown }];

export function useOrganizationMembersOfThread(props: {
  threadId: string | null | undefined;
}): UseOrganizationMembersOfThreadResult {
  const environment = useClientEnvironment();

  const observable = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([threadId, environment]) => {
          if (!threadId) return of<UseOrganizationMembersOfThreadResult>([[], { isLoading: false }]);

          return environment.recordLoader.observeGetThreadGroupPermissions({ thread_id: threadId }).pipe(
            switchMap(([permissions, meta]) => {
              const groupIds = new Set(permissions.map((p) => p.group_id));
              groupIds.delete(ADMIN_ORGANIZATION_ID);

              return environment.recordLoader.observeGetRecords(
                Array.from(groupIds).map((groupId) => getPointer("tag", groupId)),
                { isLoading: meta.isLoading, error: meta.error },
              );
            }),
            map(([groupPointersWithRecord, meta]): UseOrganizationMembersOfThreadResult => {
              const organizationGroups = groupPointersWithRecord
                .map((p) => p.record)
                .filter(isOrganizationGroupTagRecord);
              return [organizationGroups, meta];
            }),
          );
        }),
      ),
    [props.threadId, environment],
  );

  return useObservableState(observable, DEFAULT_VALUE);
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]),
  Object.freeze({ isLoading: true }),
]) as unknown as UseOrganizationMembersOfThreadResult;
