/*
 * Note, we also use some 3rd party buttons in Comms (e.g. material
 * ui's IconButton). This file contains some additional custom buttons.
 */

import { css, cx } from "@emotion/css";
import { forwardRef, PropsWithChildren, MouseEventHandler } from "react";
import { whiteA } from "@radix-ui/colors";
import { GoTriangleDown } from "react-icons/go";

const baseChipCSS = css`
  &.dark-theme {
    svg {
      color: ${whiteA.whiteA11};
    }
  }
`;

export const OutlineDropdownButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<{
    tabIndex?: number;
    theme?: "light" | "dark";
    onClick?: MouseEventHandler<HTMLButtonElement>;
  }>
>((props, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      className={cx(
        `${props.theme || "light"}-theme`,
        "flex items-center rounded border group",
        baseChipCSS,
        props.theme === "dark" ?
          "border-whiteA-9 hover:border-whiteA-11 hover:bg-whiteA-3"
        : "border-slate-9 hover:border-slate-12 hover:bg-slateA-3",
      )}
      onClick={props.onClick}
      tabIndex={props.tabIndex}
    >
      <div
        className={cx(
          "flex items-center px-2 py-1 border-r",
          props.theme === "dark" ?
            "border-whiteA-9 group-hover:border-whiteA-11 group-hover:bg-whiteA-3"
          : "border-slate-9 group-hover:border-slate-12 group-hover:bg-slate-5",
        )}
      >
        {props.children}
      </div>

      <div className="flex justify-center items-center px-2 text-slate-11">
        <small>
          <GoTriangleDown />
        </small>
      </div>
    </button>
  );
});

export const OutlineButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<
    {
      theme?: "light" | "dark";
      className?: string;
    } & React.ButtonHTMLAttributes<HTMLButtonElement>
  >
>((props, ref) => {
  const { theme, className, ...otherProps } = props;

  return (
    <button
      ref={ref}
      type="button"
      className={cx(
        `${theme || "light"}-theme`,
        "flex items-center rounded border group px-2 py-1",
        "text-nowrap",
        baseChipCSS,
        theme === "dark" ?
          "border-whiteA-9 hover:border-whiteA-11 hover:bg-whiteA-3"
        : "border-slate-9 hover:border-slate-12 hover:bg-slateA-3",
        className,
      )}
      {...otherProps}
    />
  );
});
