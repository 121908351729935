import { getPointer, RecordValue } from "libs/schema";
import { map, switchMap, Observable, debounceTime, concat } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { stringComparer } from "libs/comparers";
import { observeExpandedDraftToFieldUserIds } from "./observeExpandedDraftToFieldUserIds";

export type ObserveUsersWhoWillReceiveNotificationForNewThreadDraftResult = [
  RecordValue<"user_profile">[],
  { isLoading: boolean; error?: unknown },
];

export function observeUsersWhoWillReceiveNotificationForNewThreadDraft(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { draftId: string },
): Observable<ObserveUsersWhoWillReceiveNotificationForNewThreadDraftResult> {
  return concat(
    environment.recordLoader.createObserveGetRecordsResult<"user_profile">(),
    observeExpandedDraftToFieldUserIds(environment, props).pipe(
      debounceTime(50),
      switchMap(({ userIds, meta }) => {
        return environment.recordLoader.observeGetRecords(
          userIds.map((id) => getPointer("user_profile", id)),
          meta,
        );
      }),
    ),
  ).pipe(
    map(([profiles, meta]) => [
      profiles.map((p) => p.record).sort((a, b) => stringComparer(a.name, b.name) || stringComparer(a.id, b.id)),
      meta,
    ]),
  );
}
