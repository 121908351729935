import { UnreachableCaseError } from "libs/errors";
import { isEqual } from "libs/predicates";
import { DraftGroupRecipientDoc, TagSubscriptionPreference } from "libs/schema";
import { ADMIN_USER_ID } from "libs/shared-constants";
import { of, map, switchMap, combineLatest, distinctUntilChanged } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

/**
 * @returns looking just at the `to`field of a draft, this observable will return the user ids
 *   of all users who will receive a notification for the draft. Note that, for replies, this
 *   doesn't include participants / subscribers of the thread not included in the draft's `to`
 *   field.
 */
export function observeExpandedDraftToFieldUserIds(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { draftId: string },
) {
  return environment.recordLoader.observeGetRecord("draft", props.draftId).pipe(
    map(([draft, draftMeta]) => {
      return {
        to: draft?.to || [],
        isReply: draft?.is_reply,
        draftMeta,
      };
    }),
    distinctUntilChanged(isEqual),
    switchMap(({ to, isReply, draftMeta }) => {
      const recipients = to.reduce(
        (store, recipient) => {
          switch (recipient.type) {
            case "GROUP": {
              store.groupRecipients.push(recipient);
              break;
            }
            case "USER": {
              store.userIds.push(recipient.user_id);
              break;
            }
            default: {
              throw new UnreachableCaseError(recipient);
            }
          }

          return store;
        },
        { userIds: [] as string[], groupRecipients: [] as DraftGroupRecipientDoc[] },
      );

      if (recipients.groupRecipients.length === 0) {
        return of({ userIds: recipients.userIds, meta: draftMeta });
      }

      return combineLatest(
        recipients.groupRecipients.map((recipient) => {
          if (recipient.is_mentioned || !isReply) {
            return observeTagSubscriptionUserIds(environment, {
              tagId: recipient.group_id,
              subscriptionPreference: ["all", "all-new"],
            });
          } else {
            return observeTagSubscriptionUserIds(environment, {
              tagId: recipient.group_id,
              subscriptionPreference: ["all"],
            });
          }
        }),
      ).pipe(
        map((results) => {
          return results.reduce(
            (store, { userIds, meta }) => {
              store.userIds.push(...userIds);
              store.meta.isLoading = store.meta.isLoading || meta.isLoading;
              store.meta.error = store.meta.error ?? meta.error;
              return store;
            },
            { userIds: recipients.userIds.slice(), meta: draftMeta },
          );
        }),
      );
    }),
    map(({ userIds, meta }) => {
      const userIdsSet = new Set(userIds);
      userIdsSet.delete(ADMIN_USER_ID);
      return { userIds: Array.from(userIdsSet), meta };
    }),
  );
}

function observeTagSubscriptionUserIds(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    tagId: string;
    subscriptionPreference: TagSubscriptionPreference[];
  },
) {
  if (props.subscriptionPreference.length === 0) {
    throw new Error("[observeTagSubscriptionUserIds] if provided, subscriptionPreference must not be empty");
  }

  return combineLatest(
    props.subscriptionPreference.map((preference) => {
      return environment.recordLoader.observeGetTagSubscriptions({ tag_id: props.tagId, preference });
    }),
  ).pipe(
    map((results) => {
      return results.reduce(
        (store, [users, meta]) => {
          store.userIds.push(...users.map((u) => u.user_id));
          store.meta.isLoading = store.meta.isLoading || meta.isLoading;
          store.meta.error = store.meta.error ?? meta.error;
          return store;
        },
        { userIds: [] as string[], meta: { isLoading: false as boolean, error: undefined as unknown } },
      );
    }),
  );
}
