import { stringComparer } from "libs/comparers";
import { getPointer, RecordValue } from "libs/schema";
import { ADMIN_USER_ID } from "libs/shared-constants";
import { combineLatest, concat, debounceTime, map, Observable, of, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { observeTagIndirectUserMemberIds } from "~/observables/observeTagIndirectUserMemberIds";

export type ObserveUsersPermittedToViewThreadResult = [
  RecordValue<"user_profile">[],
  { isLoading: boolean; error?: unknown },
];

export function observeUsersPermittedToViewThread(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    threadId: string;
  },
): Observable<ObserveUsersPermittedToViewThreadResult> {
  const directlyPermittedUsers = environment.recordLoader
    .observeGetThreadUserPermissions({ thread_id: props.threadId })
    .pipe(map(([records, meta]) => ({ userIds: records.map((r) => r.user_id), ...meta })));

  const groupPermittedUsers = environment.recordLoader
    .observeGetThreadGroupPermissions({ thread_id: props.threadId })
    .pipe(
      switchMap(([groupPermissions, meta]) => {
        if (groupPermissions.length === 0) {
          return of([]);
        }

        return combineLatest(
          groupPermissions.map((p) =>
            observeTagIndirectUserMemberIds(
              environment,
              { tagId: p.group_id },
              { isLoading: meta.isLoading, error: meta.error },
            ),
          ),
        );
      }),
      map((results) =>
        results.reduce(
          (store, [userIds, meta]) => {
            store.userIds.push(...userIds);
            store.isLoading = store.isLoading || meta.isLoading;
            store.error = store.error ?? meta.error;
            return store;
          },
          {
            userIds: [] as string[],
            error: undefined as unknown,
            isLoading: false,
          },
        ),
      ),
    );

  return concat(
    environment.recordLoader.createObserveGetRecordsResult<"user_profile">(),
    combineLatest([directlyPermittedUsers, groupPermittedUsers]).pipe(
      debounceTime(50),
      switchMap(([directlyPermittedUsers, groupPermittedUsers]) => {
        const userIds = new Set([...directlyPermittedUsers.userIds, ...groupPermittedUsers.userIds]);

        userIds.delete(ADMIN_USER_ID);

        return environment.recordLoader.observeGetRecords(
          Array.from(userIds).map((id) => getPointer("user_profile", id)),
          {
            error: directlyPermittedUsers.error ?? groupPermittedUsers.error,
            isLoading: directlyPermittedUsers.isLoading || groupPermittedUsers.isLoading,
          },
        );
      }),
    ),
  ).pipe(
    map(([pointerWithRecords, meta]) => {
      const profiles = pointerWithRecords
        .map((p) => p.record)
        .sort((a, b) => stringComparer(a.name, b.name) || stringComparer(a.id, b.id));

      return [profiles, meta];
    }),
  );
}
