import { getPointer, RecordValue } from "libs/schema";
import { map, switchMap, Observable, combineLatest, debounceTime, distinctUntilChanged, concat } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { stringComparer } from "libs/comparers";
import { observeUserIdsSubscribedToThread } from "./observeUserIdsSubscribedToThread";
import { isEqual } from "libs/predicates";
import { pick } from "lodash-comms";
import { observeExpandedDraftToFieldUserIds } from "./observeExpandedDraftToFieldUserIds";

export type ObserveNewUsersWhoWillReceiveNotificationForDraftReplyResult = [
  RecordValue<"user_profile">[],
  { isLoading: boolean; error?: unknown },
];

export function observeNewUsersWhoWillReceiveNotificationForDraftReply(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: { draftId: string },
): Observable<ObserveNewUsersWhoWillReceiveNotificationForDraftReplyResult> {
  return concat(
    environment.recordLoader.createObserveGetRecordsResult<"user_profile">(),
    environment.recordLoader.observeGetRecord("draft", props.draftId).pipe(
      map(([draft, draftMeta]) => {
        return {
          draft: pick(draft, "id", "thread_id") as Pick<RecordValue<"draft">, "id" | "thread_id">,
          draftMeta,
        };
      }),
      distinctUntilChanged(isEqual),
      switchMap(({ draft, draftMeta }) => {
        if (!draft?.thread_id) {
          return environment.recordLoader.createObserveGetRecordsResult<"user_profile">([undefined, draftMeta]);
        }

        return combineLatest([
          observeExpandedDraftToFieldUserIds(environment, props),
          observeUserIdsSubscribedToThread(environment, { threadId: draft.thread_id }),
        ]).pipe(
          debounceTime(50),
          map(([draftRecipientData, [userIdsSubscribedToThread, userIdsSubscribedToThreadMeta]]) => {
            const draftUserIds = new Set(draftRecipientData.userIds);
            const threadUserIds = new Set(userIdsSubscribedToThread);
            const newUserIds = Array.from(draftUserIds.difference(threadUserIds));

            const meta = {
              isLoading:
                draftMeta.isLoading || draftRecipientData.meta.isLoading || userIdsSubscribedToThreadMeta.isLoading,
              error: draftMeta.error ?? draftRecipientData.meta.error ?? userIdsSubscribedToThreadMeta.error,
            };

            return { newUserIds, meta };
          }),
          distinctUntilChanged(isEqual),
          switchMap(({ newUserIds, meta }) => {
            return environment.recordLoader.observeGetRecords(
              newUserIds.map((id) => getPointer("user_profile", id)),
              meta,
            );
          }),
        );
      }),
    ),
  ).pipe(
    map(([profiles, meta]) => [
      profiles.map((p) => p.record).sort((a, b) => stringComparer(a.name, b.name) || stringComparer(a.id, b.id)),
      meta,
    ]),
  );
}
