import { ComponentType, useEffect } from "react";
import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "libs/promise-utils";
import { navigateService } from "~/environment/navigate.service";
import { createFormControl, createFormGroup, IFormControl, useControl } from "solid-forms-react";
import { handleSubmit, observable, useControlState } from "~/components/forms/utils";
import { useRegisterCommands } from "~/environment/command.service";
import "react-phone-number-input/style.css";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { TextareaInput } from "~/components/forms/Textarea";
import { toast } from "~/environment/toast-service";
import { SubmitDialogHint } from "../DialogLayout";
import { inviteUsers } from "~/actions/invitations";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useOrganizationProfile } from "~/hooks/useOrganizationProfile";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { combineLatest } from "rxjs";
import { cx } from "@emotion/css";
import { useOrganizationControlledDomainDomains } from "~/hooks/useOrganizationControlledDomainDomains";
import { IsLoadingDialogState } from "../LoadingModal";
import { inviteMembersToOrganizationCommand } from "~/utils/common-commands";

type InviteUsersDialogReturnData = { success?: boolean } | null;

export const InviteUsersDialogState = new DialogState<never, InviteUsersDialogReturnData>();

type FormValue = { emails: string };

export const InviteUsersDialog = withModalDialog({
  dialogState: InviteUsersDialogState,
  useOnDialogContainerRendered: () => {
    const environment = useClientEnvironment();

    useRegisterCommands({
      commands: () => {
        return [
          inviteMembersToOrganizationCommand({
            callback: () => {
              if (!environment.network.isOnline()) {
                toast("vanilla", {
                  subject: "Not supported in offline mode",
                  description: "Can't invite members when offline.",
                });

                return;
              }

              InviteUsersDialogState.open();
            },
          }),
        ];
      },
      deps: [environment],
    });
  },
  // This disables the default onBackdropClick action of "close"
  onBackdropClick: () => {},
  Component: () => {
    const environment = useClientEnvironment();
    const { ownerOrganizationId } = useAuthGuardContext();

    const [organizationProfile] = useOrganizationProfile(ownerOrganizationId);

    const [domains] = useOrganizationControlledDomainDomains({
      organizationId: ownerOrganizationId,
    });

    const control = useControl(() => {
      return createFormGroup({
        emails: createFormControl("", {
          required: true,
        }),
      });
    });

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              InviteUsersDialogState.close();
            },
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              environment.logger.debug("attempting submit");
              handleSubmit({ control, environment, submit });
            },
          },
        ];
      },
      deps: [environment],
    });

    const domainNames = domains.join(", ");

    return (
      <>
        <DialogTitle>
          <h2>Invite Users</h2>
        </DialogTitle>

        <form onSubmit={(e) => e.preventDefault()} className={DIALOG_CONTENT_WRAPPER_CSS}>
          <p className="m-4">
            Invite members to the {organizationProfile?.name} organization by entering their email addresses, separated
            by a comma. At the moment, only users with email addresses from your organization can be invited{" "}
            {domainNames && `(i.e. ${domainNames})`}.
          </p>

          <Emails control={control.controls.emails} />
        </form>

        <SubmitDialogHint />
      </>
    );
  },
});

const submit = onlyCallFnOnceWhilePreviousCallIsPending(async (environment: ClientEnvironment, values: FormValue) => {
  using disposable = IsLoadingDialogState.markIsLoading(environment);

  const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

  const emails = values.emails.split(",").map((email) => email.trim());

  await inviteUsers(environment, {
    emails,
    organizationId: ownerOrganizationId,
  });

  environment.logger.debug("submitted successfully!");

  InviteUsersDialogState.close({ success: true });
  navigateService(`/organizations/${ownerOrganizationId}/members`, {
    replace: true,
  });
});

const Emails: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  useValidateEmailAddresses(props.control);

  const isInvalid = useControlState(() => !props.control.isValid, [props.control]);

  const isTouched = useControlState(() => props.control.isTouched, [props.control]);

  const errorMsgs = useControlState(
    () =>
      props.control.errors &&
      Object.values(props.control.errors)
        .filter((v) => typeof v === "string")
        .join("\n"),
    [props.control],
  );

  const showErrors = isTouched && isInvalid;

  return (
    <div className="flex flex-col px-4">
      <div className={cx("flex flex-1 py-2 border-b border-mauve-5", showErrors ? "text-red-9" : "black")}>
        <label htmlFor="emails" className="mr-4">
          Emails
        </label>

        <TextareaInput name="emails" control={props.control} />
      </div>

      {showErrors && (
        <div className="text-red-9 my-2">
          <small>{errorMsgs}</small>
        </div>
      )}
    </div>
  );
};

function useValidateEmailAddresses(control: IFormControl<string>) {
  const environment = useClientEnvironment();

  useEffect(() => {
    const sub = combineLatest([
      environment.recordLoader.observeGetOrganizationControlledDomains({
        organization_id: environment.auth.getAndAssertCurrentUserOwnerOrganizationId(),
      }),
      observable(() => control.value),
      observable(() => control.isTouched),
    ]).subscribe(([[controlledDomainRecords], emailInput, isTouched]) => {
      if (!isTouched) return;

      if (controlledDomainRecords.length === 0) {
        control.setErrors({
          useValidateEmailAddresses_Domains: `Cannot connect to server.`,
        });

        return;
      }

      control.patchErrors({
        useValidateEmailAddresses_Domains: null,
      });

      const controlledDomains = controlledDomainRecords.map((record) => record.domain);

      const emails = emailInput.split(",").map((email) => email.trim());

      for (const email of emails) {
        const emailDomain = email.split("@")[1];

        if (!emailDomain) {
          control.patchErrors({
            useValidateEmailAddresses: `Invalid email address "${emailDomain}"`,
          });

          return;
        }

        if (!controlledDomains.includes(emailDomain)) {
          control.patchErrors({
            useValidateEmailAddresses: `Invalid email domain "${emailDomain}": currently you can only invite users with email addresses from your organization.`,
          });

          return;
        }
      }

      control.patchErrors({ useValidateEmailAddresses: null });
    });

    return () => sub.unsubscribe();
  }, [control, environment]);
}
