import * as ThreadLayout from "~/page-layouts/thread-layout";
import { IComposeMessageForm } from "~/components/ComposeMessageContext";
import { Tooltip } from "~/components/Tooltip";
import { ComponentType } from "react";
import { Avatar } from "~/components/Avatar";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import { useUsersWhoWillReceiveNotificationForNewThreadDraft } from "~/hooks/useUsersWhoWillReceiveNotificationForNewThreadDraft";
import { useControlState } from "~/components/forms/utils";
import { useUsersWhoWillBePermittedToViewNewThread } from "~/hooks/useUsersWhoWillBePermittedToViewNewThread";
import { useOrganizationMembersOfDraft } from "~/hooks/useOrganizationMembersOfDraft";
import { hideComposeInfoPanelCommand } from "~/utils/common-commands";

/* -------------------------------------------------------------------------------------------------
 *  ThreadInfoPanel
 * -------------------------------------------------------------------------------------------------
 */

export const ComposeInfoPanel: ComponentType<{
  control: IComposeMessageForm;
}> = (props) => {
  const draftId = useControlState(() => props.control.rawValue.messageId, [props.control]);
  const visibility = useControlState(() => props.control.rawValue.visibility, [props.control]);
  const to = useControlState(() => props.control.rawValue.recipients.to, [props.control]);

  const [audienceProfiles, { isLoading: areAudienceProfilesLoading }] =
    useUsersWhoWillReceiveNotificationForNewThreadDraft({
      draftId,
    });

  // If the thread is shared, then we don't want to bother fetching everyone who can view the thread.
  // Instead we can simply see which organizations it's shared with and display that information.
  const [permissions, { isLoading: arePermissionsLoading }] = useUsersWhoWillBePermittedToViewNewThread({
    draftId: visibility === "PRIVATE" ? draftId : null,
  });

  const [organizationMembers, { isLoading: areOrgMembersLoading }] = useOrganizationMembersOfDraft({
    draftId: visibility === "SHARED" ? draftId : null,
  });

  if (to.length === 0) return <NewThreadHint />;

  return (
    <ThreadLayout.InfoPanel onBackdropClick={() => hideComposeInfoPanelCommand.trigger()}>
      <div className="p-4">
        <h2 className="text-lg font-medium text-slate-9">Draft details</h2>

        <Tooltip side="bottom" content="Notifications will be delivered to the inbox of these users.">
          <p className="mt-4 text-slate-9 cursor-help">Recipients (?)</p>
        </Tooltip>

        <div className="mt-2 flex flex-wrap">
          {audienceProfiles.length === 0 && areAudienceProfilesLoading ?
            "Loading..."
          : audienceProfiles.length === 0 ?
            <span className="text-sm font-medium">No recipients.</span>
          : audienceProfiles.map((profile) => (
              <Tooltip key={profile.id} side="bottom" content={profile.name}>
                <div className="p-1">
                  <Avatar label={profile.name} photoURL={profile.photo_url} />
                </div>
              </Tooltip>
            ))
          }
        </div>

        <Tooltip side="bottom" content={`These users will have permission to view this thread.`}>
          <p className="mt-4 text-slate-9 cursor-help">Will be able to view (?)</p>
        </Tooltip>

        <div className="mt-2 flex flex-wrap">
          {(() => {
            if (visibility === "SHARED") {
              return (
                organizationMembers.length === 0 && areOrgMembersLoading ? "Loading..."
                : organizationMembers.length === 0 ? <span className="text-sm font-medium">Error.</span>
                : organizationMembers.map((m) => `Everyone in the ${m.name}.`).join(" ")
              );
            } else {
              return (
                permissions.length === 0 && arePermissionsLoading ? "Loading..."
                : permissions.length === 0 ? <span className="text-sm font-medium">No recipients.</span>
                : permissions.map((profile) => (
                    <Tooltip key={profile.id} side="bottom" content={profile.name}>
                      <div className="p-1">
                        <Avatar label={profile.name} photoURL={profile.photo_url} />
                      </div>
                    </Tooltip>
                  ))
              );
            }
          })()}
        </div>

        <ClosePanelButton />
      </div>
    </ThreadLayout.InfoPanel>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const NewThreadHint: ComponentType = () => {
  return (
    <ThreadLayout.InfoPanel onBackdropClick={() => hideComposeInfoPanelCommand.trigger()}>
      <div className="p-4">
        <p className="mt-4">
          <strong>Hint</strong>
        </p>

        <div className="mt-2 prose">
          <p>
            <code>@mention</code> someone to loop them into this conversation. <code>@@request-response</code> someone
            to loop them into the conversation and also request a response from them. Including someone in the message's
            recipient field is the same as <code>@mentioning</code> them in the body.
          </p>
        </div>

        <ClosePanelButton />
      </div>
    </ThreadLayout.InfoPanel>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ClosePanelButton: ComponentType = () => {
  return (
    <Tooltip side="top" content="Close details panel">
      <button
        type="button"
        className="absolute bottom-4 right-4 text-slate-9 hover:text-black"
        onClick={(e) => {
          e.preventDefault();
          hideComposeInfoPanelCommand.trigger();
        }}
      >
        <IoMdClose className="hidden text-4xl md-w:block" />
        <IoMdCloseCircle className="text-5xl md-w:hidden" />
      </button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/
