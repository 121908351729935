import { RecordValue } from "libs/schema";
import { uniq } from "lodash-comms";
import { combineLatest, concat, debounceTime, map, Observable, of, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ObserveOptions } from "~/environment/RecordLoader";

export type ObserveTagIndirectUserMembersIdsResult = [string[], { isLoading: boolean; error?: unknown }];

/**
 * @returns an observable that will return the group ids of all groups which are members of
 *   the provided tag, recursively.
 */
export function observeTagIndirectGroupMemberIds(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    tagId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveTagIndirectUserMembersIdsResult> {
  return concat(
    of<ObserveTagIndirectUserMembersIdsResult>([[], { isLoading: true }]),
    innerObserveTagIndirectGroupMembers(environment, props, options).pipe(
      debounceTime(50),
      map(({ groupMembers, error, isLoading }): ObserveTagIndirectUserMembersIdsResult => {
        const tagIds = uniq(groupMembers.map((m) => m.tag_id));
        return [tagIds, { error, isLoading }];
      }),
    ),
  );
}

type ObserveTagIndirectGroupMembersResult = {
  groupMembers: RecordValue<"tag_group_member">[];
  error: unknown;
  isLoading: boolean;
};

function innerObserveTagIndirectGroupMembers(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    tagId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveTagIndirectGroupMembersResult> {
  return environment.recordLoader.observeGetTagGroupMembers({ tag_id: props.tagId }, options).pipe(
    switchMap(([groupMembers, meta]) => {
      if (groupMembers.length === 0) {
        return of<ObserveTagIndirectGroupMembersResult>({
          groupMembers,
          error: meta.error,
          isLoading: meta.isLoading,
        });
      }

      return combineLatest(
        groupMembers.map((m) => innerObserveTagIndirectGroupMembers(environment, { tagId: m.group_id }, meta)),
      ).pipe(
        map((results): ObserveTagIndirectGroupMembersResult => {
          return results.reduce(
            (store, { groupMembers, error, isLoading }) => {
              store.groupMembers.push(...groupMembers);
              store.isLoading = store.isLoading || isLoading;
              store.error = store.error ?? error;
              return store;
            },
            { groupMembers: groupMembers.slice(), error: meta.error, isLoading: meta.isLoading },
          );
        }),
      );
    }),
  );
}
