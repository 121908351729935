import { GetOptions } from "~/environment/RecordLoader";
import { MentionableUser } from "~/observables/observeMentionableUsers";
import { getMentionableUser } from "./getMentionableUser";
import { isNonNullable } from "libs/predicates";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { uniq } from "lodash-comms";

/* -------------------------------------------------------------------------------------------------
 * getMentionableUsers
 * -------------------------------------------------------------------------------------------------
 */

export type GetMentionableUserRecordsResult = MentionableUser[];

export async function getMentionableUsers(
  environment: Pick<ClientEnvironment, "auth" | "recordLoader">,
  options?: GetOptions,
): Promise<GetMentionableUserRecordsResult> {
  const userIds = await getMentionableUserIds(environment, options);

  const results = await Promise.all(userIds.map((userId) => getMentionableUser(environment, { id: userId }, options)));

  return results.filter(isNonNullable);
}

/* -----------------------------------------------------------------------------------------------*/

async function getMentionableUserIds(
  environment: Pick<ClientEnvironment, "auth" | "recordLoader">,
  options?: GetOptions,
): Promise<string[]> {
  const currentUserId = environment.auth.getAndAssertCurrentUserId();

  const [currentUsersOrganizations] = await environment.recordLoader.getOrganizationUserMembers(
    { user_id: currentUserId },
    options,
  );

  const organizationIds = currentUsersOrganizations.map((r) => r.organization_id);

  const results = await Promise.all(
    organizationIds.map((organization_id) =>
      environment.recordLoader.getOrganizationUserMembers({ organization_id }, options),
    ),
  );

  const userIds = uniq(results.flatMap(([records]) => records.map((r) => r.user_id)));

  return userIds;
}

/* -----------------------------------------------------------------------------------------------*/
